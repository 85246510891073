import React from 'react'
import Header from './Header'
import Overlay from '../Overlay'
import ReturnButton from './ReturnButton'

const WelcomeSection: React.FC = () => {
  return (
    <div className='bg-hidden bg-thankyou bg-no-repeat relative'>
      <section
        className={`flex flex-col bg-thankyou bg-no-repeat bg-[length:720px_480px] sm:bg-[length:1080px_720px] lg:bg-cover gap-10 md:h-[90vw] md:max-h-[720px] xl:max-h-[1080px] 2xl:max-h-[1080px] bg-top xl:h-[52vw]`}
      >
        <Header logoColor='white' />
        <div className='flex flex-col items-center mb-12 md:mb-20'>
          <div className='text-center md:tracking-widest text-white font-Agatho text-mb mb:text-2xl lg:text-4xl xl:text-5xl 2xl:text-6xl'>
            <h1 className=''>FORTUNE FAVORS THE BOLD, AND</h1>
            <h1>WE’RE GLAD TO HAVE YOU WITH US.</h1>
          </div>
          <div className='text-center text-white font-Agatho text-sm mb:text-2xl lg:text-2xl py-5'>
            <p>Thanks for your interest in AGIA. We’ll be in touch soon.</p>
            <p>Please be sure to check your spam folder.</p>
          </div>
          <div className='py-2'>
            <ReturnButton />
          </div>
        </div>
      </section>
    </div>
  )
}
export default WelcomeSection
