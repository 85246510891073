import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import LandingPage from './pages/LandingPage'
import GalleryPage from './pages/GalleryPage'
import EstatePage from './pages/EstatePage'
import ThankYouPage from './pages/ThankYouPage'

const App: React.FC = () => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])


  // useEffect(() => {
  //   (window as any).gtag('event', 'page_view', {
  //     page_path: location.pathname + location.search + location.hash,
  //     page_search: location.search,
  //     page_hash: location.hash,
  //   });
  // }, [location]);

  return (
    <Routes>
      <Route path='/' element={<LandingPage />} />
      <Route path='/gallery' element={<GalleryPage />} />
      <Route path='/estate/:id' element={<EstatePage />} />
      <Route path='/thank-you' element={<ThankYouPage />} />
    </Routes>
  )
}

export default App
