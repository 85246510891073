import React, { useEffect } from 'react'
import IntroSection from '../components/GalleryPage/IntroSection'
import ShowcaseSection from '../components/GalleryPage/ShowcaseSection'
import EstateSection from '../components/GalleryPage/EstateSection'
import FormAndFooter from '../components/LandingPage/FormAndFooterSection'

const GalleryPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Agia - Gallery';
  }, []);

  return (
    <div>
      <IntroSection />
      <ShowcaseSection />
      <EstateSection />
      <FormAndFooter />
    </div>
  )
}
export default GalleryPage
