import React from 'react'
import Header from './Header'
import DisconnectWallet from '../components/DisconnectWallet'
import { useEthers } from '@usedapp/core'

function EstateHeader() {
  const { account } = useEthers()
  return (
    <div className='bg-estateBackground bg-cover bg-center relative'>
      <section className='flex flex-col justify-between bg-gradient-to-t from-black/50 to-black/3 h-full'>
        <div>
          <Header logoColor='white' />
          <div className='text-center font-Agatho text-2xl md:text-4xl lg:text-6xl 2xl:text-7xl text-white mb-36 md:my-6 md:my-12 lg:px-20'>
            MINT AN ALLOWLIST NFT TO RESERVE YOUR HOMESITE
          </div>
          {account ? (
            <div
              style={{ margin: '15px' }}
              className='w-[95%] text-center flex-1 md:flex md:self-start justify-end md:justify-end lg:justify-end items-center'
            >
              <DisconnectWallet />
            </div>
          ) : null}
        </div>
      </section>
    </div>
  )
}

export default EstateHeader
