import React from 'react'
import Agia from './Agia'

type Props = {
  color?: string
}

const Logo: React.FC<Props> = ({ color = 'agia-primary' }) => {
  return (
    <div className='flex flex-col w-full items-center px-8 py-2 my-2 md:px-12 md:py-4 md:mt-20 lg:px-16 lg:py-6'>
      <Agia color={color} />
      <div
        className={`flex flex-col text-${color} text-[.7rem]  lg:text-lg font-Montserrat font-medium tracking-[.3rem]`}
      >
        <p>GREAT EXUMA</p>
        <p className='self-center m-0'>BAHAMAS</p>
      </div>
    </div>
  )
}
export default Logo
