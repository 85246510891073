import { createSlice } from '@reduxjs/toolkit'
import { Accordian, FAQDataBasic } from '../utils/FAQdata'

export type FAQCategoryState = {
  category: string
  data: Accordian[]
}

const initialState: FAQCategoryState = {
  category: 'Basic',
  data: FAQDataBasic
}

const modalSlice = createSlice({
  name: 'faqCategory',
  initialState,
  reducers: {
    setCategory: (state, action) => {
      state.category = action.payload
    },
    setData: (state, action) => {
      state.data = action.payload
    }
  }
})
export const { setCategory, setData } = modalSlice.actions
export default modalSlice.reducer
