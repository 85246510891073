import React from 'react'
import ShowcaseCard from '../ShowcaseCard'
import showcaseImg1 from '../../assets/galleryPageImg/showcaseImg1.jpg'
import showcaseImg2 from '../../assets/galleryPageImg/showcaseImg2.jpg'
import showcaseMap from '../../assets/galleryPageImg/showcaseMap.jpg'
import Overlay from '../Overlay'

const ShowcaseSection: React.FC = () => {
  const cardDescription1 = `A select number of shovel-ready homesites allow
owners to customize their estate in partnership with
architect Chad Oppenheim. All Villa homesites
include a deeded, private floating boat slip.`
  const cardDescription2 = `With one light-filled bedroom and bath, Pavilions
are fully turnkey, waterfront homes. All Pavilions
include a deeded, private floating boat slip.`
  return (
    <section className='flex flex-col mt-6'>
      <Overlay color={'from-[rgba(242,236,229,0.55)]'} options={'h-[5%]'}>
        <div className='flex flex-col md:flex-row items-start md:justify-between px-4 md:p-8 lg:px-12 xl:px-16 2xl:px-24'>
          <ShowcaseCard
            title={'PAVILIONS'}
            minPrice={'5k'}
            maxPrice={'10k'}
            description={cardDescription2}
            src={showcaseImg2}
            alt={'Luxurious home by the pool view'}
          />
          <ShowcaseCard
            title={'VILLA HOMESITES'}
            minPrice={'5k'}
            maxPrice={'10k'}
            description={cardDescription1}
            src={showcaseImg1}
            alt={'Luxurious home outdoor view'}
          />
        </div>
      </Overlay>
      <div className='flex flex-col items-center justify-center my-4 lg:my-6 xl:my-8'>
        <h1 className='font-AjensonPro text-2xl lg:text-4xl text-title-blue'>LOCATION MAP</h1>
        <figure className='w-full mt-2 lg:my-6 xl:my-8'>
          <img className='w-full h-full object-cover' src={showcaseMap} alt='map of the estate' />
        </figure>
      </div>
    </section>
  )
}

export default ShowcaseSection
