import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import App from './App'
import reportWebVitals from './reportWebVitals'
import 'react-toastify/dist/ReactToastify.css'
import './index.css'
import './polyfill'

import { Fantom, DAppProvider, Config } from '@usedapp/core'


const config: Config = {
  readOnlyChainId: Fantom.chainId,
  readOnlyUrls: {
    [Fantom.chainId]: 'https://fantom-mainnet.gateway.pokt.network/v1/lb/62a01fb487017d0039b67d74'
  }
}

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <DAppProvider config={config}>
        <Provider store={store}>
          <App />
          <ToastContainer
            position='top-center'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            icon={true}
            theme='colored'
            pauseOnHover={false}
            rtl={false}
          />
        </Provider>
      </DAppProvider>
    </BrowserRouter>
  </React.StrictMode>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
