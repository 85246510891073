import carouselImg1 from '../assets/images/carouselImg1.jpg'
import carouselImg2 from '../assets/images/carouselImg2.jpg'
import carouselImg3 from '../assets/images/carouselImg3.jpg'
import carouselImg4 from '../assets/images/carouselImg4.jpg'
import carouselImg5 from '../assets/images/carouselImg5.jpg'
import carouselImg6 from '../assets/images/carouselImg6.jpg'
import carouselImg7 from '../assets/images/carouselImg7.jpg'
import carouselImg8 from '../assets/images/carouselImg8.jpg'
import carouselImg9 from '../assets/images/carouselImg9.jpg'
import carouselImg10 from '../assets/images/carouselImg10.jpg'
import carouselImg11 from '../assets/images/carouselImg11.jpg'

export type CarouselData = {
  title: string
  imageUrl: string
  width: string
}

export const data: CarouselData[] = [
  {
    title: 'Carousel 1',
    imageUrl: carouselImg1,
    width: 'md:w-42 lg:w-60 2xl:w-[20rem]'
  },
  {
    title: 'Carousel 2',
    imageUrl: carouselImg2,
    width: 'md:w-96 lg:w-[32rem] 2xl:w-[48rem]'
  },
  {
    title: 'Carousel 3',
    imageUrl: carouselImg3,
    width: 'md:w-96 lg:w-[32rem] 2xl:w-[48rem]'
  },
  {
    title: 'Carousel 4',
    imageUrl: carouselImg4,
    width: 'md:w-96 lg:w-[32rem] 2xl:w-[48rem]'
  },
  {
    title: 'Carousel 5',
    imageUrl: carouselImg5,
    width: 'md:w-96 lg:w-[32rem] 2xl:w-[48rem]'
  },
  {
    title: 'Carousel 6',
    imageUrl: carouselImg6,
    width: 'md:w-42 lg:w-60 2xl:w-[20rem]'
  },
  {
    title: 'Carousel 7',
    imageUrl: carouselImg7,
    width: 'md:w-64 lg:w-72 2xl:w-[32rem]'
  },
  {
    title: 'Carousel 8',
    imageUrl: carouselImg8,
    width: 'md:w-96 lg:w-[32rem] 2xl:w-[48rem]'
  },
  {
    title: 'Carpusel 9',
    imageUrl: carouselImg9,
    width: 'md:w-96 lg:w-[32rem] 2xl:w-[48rem]'
  },
  {
    title: 'Carpusel 10',
    imageUrl: carouselImg10,
    width: 'md:w-42 lg:w-60 2xl:w-[20rem]'
  },
  {
    title: 'Carpusel 11',
    imageUrl: carouselImg11,
    width: 'md:w-96 lg:w-[32rem] 2xl:w-[48rem]'
  }
]
