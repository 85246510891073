import { useEthers, useContractFunction } from '@usedapp/core'
import React, { useEffect, useState, useCallback } from 'react'
import PrimaryButton from './PrimaryButton'
import WalletConnectProvider from '@walletconnect/web3-provider'
import Web3Modal from 'web3modal'
import { toast } from 'react-toastify'
import { useMainContract, useWhitelistContract } from '../utils/useContract'
import Swal from 'sweetalert2'
import { Route, Link, Routes, useParams } from 'react-router-dom'
import { ethers } from 'ethers'

const fontClass = {
  default: 'text-xl',
  lg: 'lg:text-3xl'
}

type Props = {
  estateImage: string
  estateName: string
  price: string
  mapImage?: string
  keyDetails: { content: string; style?: string }[]
}

function EstateKeyDetails(props: Props) {
  const { account, activate, chainId, error, library } = useEthers()
  const [activateError, setActivateError] = useState('')
  const [image, setImage] = useState()
  const params = useParams()
  const tokenId = params['id']
  const providerOptions = {
    injected: {
      display: {
        name: 'Metamask',
        description: 'Connect with the provider in your Browser'
      },
      package: null
    },
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        bridge: 'https://bridge.walletconnect.org',
        infuraId: '4105887ba183256adeea302ef242cd55'
      }
    }
  }

  const web3Modal = new Web3Modal({
    cacheProvider: true, // optional
    providerOptions
  })

  useEffect(() => {
    if (error) {
      setActivateError(error.message)
    }
  }, [error])

  useEffect(() => {
    if (activateError) {
      toast.error(activateError)
    }
  }, [activateError])

  const activateProvider = async () => {
    try {
      const provider = await web3Modal.connect()
      await activate(provider)
      setActivateError('')
    } catch (error: any) {
      setActivateError(error.message)
    }
  }

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      activateProvider()
    }
  }, [])

  const whitelistContract = useWhitelistContract()
  const mainContract = useMainContract()

  const { send: whitelistMintSend, state: whitelistMintState } = useContractFunction(
    whitelistContract,
    'buyToken',
    { transactionName: 'Buy Whitelist Token' }
  )

  const { send: mainMintSend, state: mainMintState } = useContractFunction(
    mainContract,
    'buyToken',
    { transactionName: 'Buy Token' }
  )

  useEffect(() => {
    {
      whitelistMintState.status === 'Exception' &&
        Swal.fire({
          title: 'Error!',
          text: whitelistMintState.errorMessage,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
    }

    {
      whitelistMintState.status === 'Success' &&
        Swal.fire({
          title: 'Success!',
          text: 'Token successfully minted',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
    }
  }, [whitelistMintState])

  useEffect(() => {
    {
      mainMintState.status === 'Exception' &&
        Swal.fire({
          title: 'Error!',
          text: mainMintState.errorMessage,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
    }

    {
      mainMintState.status === 'Success' &&
        Swal.fire({
          title: 'Success!',
          text: 'Token successfully minted',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
    }
  }, [mainMintState])


  const handleWhitelistMint = async () => {
    const tokenPrice = await whitelistContract.getTokenPrice((Number(tokenId) - 1).toString())
    whitelistMintSend((Number(tokenId) - 1).toString(), { value: tokenPrice.toString() })
  }

  const handleMainMint = async () => {
    const tokenMainPrice = await mainContract.getTokenPrice((Number(tokenId) - 1).toString(), account)
    mainMintSend((Number(tokenId) - 1).toString(), { value: tokenMainPrice.toString() })
  }

  useEffect(() => {
    const fetchImage = async () => {
      try {
        let response
        if (props.estateName.includes('Villa')) {
          response = await import(`../assets/estateAssets/WL_Villas/${props.estateImage}`)
        } else {
          response = await import(`../assets/estateAssets/WL_Pavillions/${props.estateImage}`)
        }

        setImage(response.default)
      } catch (err) {
        console.log(err)
      }
    }

    fetchImage()
  }, [props.estateImage])
  return (
    <section className='relative flex flex-col md:flex-row items-center justify-center py-10 px-4 md:px-10 lg:px-24 xl:px-32 2xl:px-56'>
      <div className='flex flex-row justify-center md:justify-end w-2/3 md:w-1/2 md:relative absolute -top-[20%] md:-top-[0%] w-full pr-0 sm:pr-2 lg:pr-8 xl:pr-14'>
        <div className='w-60 sm:w-72 md:w-96 lg:w-112'>
          <img className='border-agia border-2' src={image} />
          <div className='h-4 bg-gradient-to-b from-black/30 to-transparent'>
            <div className='h-4 bg-gradient-to-r from-white via-transparent to-white'></div>
          </div>
        </div>
      </div>

      <div className='mt-36 sm:mt-48 md:mt-0 w-full md:w-1/2 ml-4'>
        <header className='text-start sm:text-center md:text-start font-AjensonPro tracking-tight text-2xl lg:text-5xl text-title-blue mt-4 lg:mt-6'>
          {props.estateName}
        </header>
        <h1 className='text-start sm:text-center md:text-start font-AJensonProSemiBoldIt lg:font-AjensonPro text-lg lg:text-2xl text-agia-primary mt-2'>
          ALLOWLIST PRICE: {props.price}
        </h1>
        <h1 className='text-start sm:text-center md:text-start font-AJensonProSemiBoldIt lg:font-AjensonPro text-lg lg:text-2xl text-agia-primary mb-2'>
          FULL PROPERTY PRICE STARTING AT: ${props.keyDetails[3]?.content?.split('$')[1].slice(0, -3)}
        </h1>

        <div className='block hidden leading-6 tracking-tight my-2 md:mt-8 font-AjensonPro font-normal text-agia-primary text-lg md:text-xl lg:text-2xl'>
          <p className='md-0 md:mb-2'>
            A select number of shovel-ready homesites allow owners to customize their estate in
            partnership with architect Chad Oppenheim. All Villa homesites include a deeded, private
            floating boat slip.
          </p>
        </div>
        {/* {account ? (
          <>
            <div className='flex flex-row justify-items-start'>
              <PrimaryButton
                onClick={handleWhitelistMint}
                message='WHITELIST MINT'
                fontSize={fontClass}
              />
            </div>
            <div className='flex flex-row justify-items-start'>
              <PrimaryButton onClick={handleMainMint} message='MINT' fontSize={fontClass} />
            </div>
          </>
        ) : (
          <div className='flex flex-row justify-items-start'>
            <PrimaryButton
              onClick={activateProvider}
              message='CONNECT WALLET'
              fontSize={fontClass}
            />
          </div>
        )} */}
        <div className='text-start sm:text-center md:text-start sm:block mt-4 md:mt-8 font-AjensonPro font-normal text-agia-primary text-lg md:text-xl lg:text-2xl'>
          {props.keyDetails.map((item, index) => (
            <p className={`md-0 md:mb-2 ${item.style && 'font-AJensonProSemiBoldIt'}`} key={index}>
              {item.content}
            </p>
          ))}
        </div>
      </div>
    </section>
  )
}

export default EstateKeyDetails
