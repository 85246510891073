export interface Accordian {
  id: number
  title: string
  contents: { __html: string }
}
export const FAQDataBasic: Accordian[] = [
  {
    id: 1,
    title: 'What is the AGIA Key?',
    contents: {
      __html: `
      • A Non-Fungible Token (NFT) that will be used as proof-of-ownership of your land plot and function as your access key to the breadth of amenities made available to AGIA Key holders.
      <a style="color: blue;" href="#amenities">(Read More)</a>`
    }
  },
  {
    id: 2,
    title: 'How to claim your AGIA Key?',
    contents: {
      __html: `
      • <a style="color: blue;" href="https://ss-usa.s3.amazonaws.com/c/309005312/media/2136633f117808c6991097377911181/AGIA-Discovery-Brochure.pdf">Link to brochure</a>
      <br/>
      • We will guide you through the KYC / BIA process. Your official AGIA Key will be airdropped to you upon completion.
    `
    }
  },
  {
    id: 3,
    title: 'What is the Allow List?',
    contents: {
      __html: `
      • Functioning as your “membership reservation”, the Whitelist NFT is an immutable asset that represents the beginning of your journey to a world-first experience with AGIA.
    `
    }
  },
  {
    id: 4,
    title: 'How to join the Allow List?',
    contents: {
      __html: `
      • Simply fill-in and submit the “DISCOVER MORE” form below on this website.
      <br/>
      • One of the AGIA team will reach out to you to guide you through the process.
    `
    }
  },
  {
    id: 5,
    title: 'What do I need to be ready to join?',
    contents: {
      __html: `
      • A cryptocurrency “hot wallet” (for example Metamask).
      <br/>
      • One of the AGIA team will reach out to you to guide you through the process.
    `
    }
  },
  {
    id: 6,
    title: 'Can I pay with FIAT?',
    contents: {
      __html: `
      • Yes, thanks to our partnership with Gemini, a leading and regulated cryptocurrency exchange, we are able to accommodate wire transfers from FIAT to Ethereum. This allows anyone with a wire-approved bank to purchase a plot with AGIA.
    `
    }
  },
  {
    id: 7,
    title: 'How do I get in touch with AGIA?',
    contents: {
      __html: `
      • First, please complete the “DISCOVER MORE” form below on this website
      <br/>
      • Optionally, you are invited to join our <a style="color: blue;" href="https://discord.gg/agialiving">Discord</a> <a style="color: blue;" href="https://twitter.com/agialiving?s=21&t=Sw6DGvgTFI_KmnWex236Ng">Twitter</a> and <a style="color: blue;" href="https://instagram.com/agialiving?igshid=YmMyMTA2M2Y=">Instagram</a> (hyperlinks) to learn more about AGIA, meet the team, and our community.
      
    `
    }
  }
]

export const FAQDataLegal: Accordian[] = [
  {
    id: 1,
    title: 'How do I purchase an AGIA property?',
    contents: {
      __html: `
      • STEP 1: Mint an Allowlist NFT to reserve your turnkey Pavilion or Villa homesite.
      <br/>
      • STEP 2: Mint your Right-to-Own NFT during the exclusive Allowlist period.
      <br/>
      • STEP 3: AGIA initiates closing documents for execution and title transfer.
      <br/>
      • STEP 4: Receive your airdropped AGIA Key 
`
    }
  },
  {
    id: 2,
    title: 'Is there a building code?',
    contents: {
      __html: `
      • All AGIA properties will adhere to Miami-Dade County Building code.
    `
    }
  },
  {
    id: 3,
    title: 'How long will it take to build my home?',
    contents: {
      __html: `
      • The construction schedule for a Pavilion currently estimated at 9-12 months. The construction schedule for a Custom Villa Estate Home is estimated at 15-18 months.
      <br/>
      • Villa customizations include an additional 6-8 weeks before construction begins.`
    }
  },
  {
    id: 4,
    title:
      'If I want to customize the interior of my residence, how is the cost difference handled?',
    contents: {
      __html: `
      • For pavilions, minor changes to the turn key package can be accommodated. Requests will be considered on an individual basis and priced at cost plus.
      <br/>
      • For Villas, all home designs are custom. There is a $100,000 design budget included in the purchase of the villa for your work with Oppenheim Group. Anything that exceeds the allotted amount will be paid for separately by the homeowner.    `
    }
  }
]

export const FAQDataTaxes: Accordian[] = [
  {
    id: 1,
    title: 'What is the Bahamian tax structure?',
    contents: {
      __html: `
      • In the Bahamas, capital gains, corporate earnings, personal income, inheritance, dividends, resident corporations, partnerships, 
      and trusts are all tax-free for residents. In addition, AGIA homeowners who apply for permanent residency are exempt from annual property taxes in the Bahamas.`
    }
  },
  {
    id: 2,
    title: 'What is the VAT (Value-Added Tax)?',
    contents: {
      __html: `
      • The Bahamas imposes a 10% VAT tax on the value of the land only
    `
    }
  },
  {
    id: 3,
    title: 'How does the AGIA Key work?',
    contents: {
      __html: `
      • A Non-Fungible Token (NFT) will be used as right-to-ownership of your land parcel and function as your access key to the breadth of amenities made available to AGIA Key holders.    `
    }
  },
  {
    id: 4,
    title: 'Do I have to be a club member if I own a residence at AGIA?',
    contents: {
      __html: `
      • Yes, club membership is a requisite and included in your purchase.
    `
    }
  },
  {
    id: 5,
    title: 'Property management',
    contents: {
      __html: `
      • AGIA is managed by The Setai Group, the visionaries behind projects including The Setai, Miami Beach, and Amanyara in Turks and Caicos. As award-winning, international leaders in hospitality, The Setai Group, led by Michael Breene brings their unprecedented experience, luxury, and service to all AGIA’s offerings.
    `
    }
  },
  {
    id: 6,
    title: 'Will slips be for lease or purchase?',
    contents: {
      __html: `
      • Purchase
`
    }
  },
  {
    id: 7,
    title: 'Will the marina have fuel?',
    contents: {
      __html: `
      • Yes    
    `
    }
  },
  {
    id: 8,
    title: 'Will the club have a boat?',
    contents: {
      __html: `
      • Yes      
    `
    }
  },
  {
    id: 9,
    title: 'What are the slip sizes?',
    contents: {
      __html: `
      • 50 slips ranging from 100 to 400 feet in length
      <br/>
      • 20 feet long floating concrete IPE decking, built to withstand a category 3 hurricane      
    `
    }
  },
  {
    id: 10,
    title: 'What is the Dry Storage facility?',
    contents: {
      __html: `
      • The Bahamas’ first storage facility built to withstand category 5 hurricanes
      <br/>
      • 125 fully enclosed units for vessels up to 50 feet
      <br/>
      • 24-hour staffed security, video monitoring, locked, and alarm-protected
      <br/>
      • On-site repair and maintenance with service plans for AGIA members
      <br/>
      • Private boat shuttle service for drop-off and pick-up
    `
    }
  },
  {
    id: 11,
    title: 'Other activities and facilities',
    contents: {
      __html: `
      • Restaurants and bars, including wine rooms for members
      <br/>
      • Library, private cinema and cigar lounge
      <br/>
      • A welcoming, family-friendly atmosphere
      <br/>
      • On-site market and provisioning
      <br/>
      • State-of-the-art fitness center with personalized instruction
      <br/>
      • Spa, wellness center, and sports facility
      <br/>
      • Exclusive social programming, family activities, and holiday celebrations
    `
    }
  },
  {
    id: 12,
    title: 'What other services will be available for AGIA Key holders',
    contents: {
      __html: `
      • A revolutionary studio at the heart of AGIA, Sol Sounds Mint House is a destination for award-winning recording artists to create among our inspiring surroundings. Beyond state-of-the-art recording facilities, the studio is also home to a private lounge and outdoor terrace where AGIA Keyholders can join industry icons for curated cocktails and stirring conversations.
    `
    }
  }
]

export const FAQDataResidency: Accordian[] = [
  {
    id: 1,
    title: 'Where is AGIA located?',
    contents: {
      __html: `
      • AGIA is located in The Bahamas on the island of Great Exuma; the Exumas are celebrated for their sapphire-blue waters and secluded, flawless coastlines. Great Exuma is the heart of this Bahamian island chain, drawing families and adventurers who seek its outdoor pursuits, from boating and sportfishing to swimming with the famous Bahamian pigs.`
    }
  },
  {
    id: 2,
    title: 'Can I fly private?',
    contents: {
      __html: `
      • Less than a 10-minute ride from AGIA, Exuma International Airport hosts direct flights year-round. It is currently undergoing a $65 million renovation, making it the largest airport under construction in the Caribbean. With a new $15 million facility scheduled to open Fall of 2022, a Fixed-Base Operator (FBO) will offer expedited arrival for those traveling via private aviation. Regardless of how you arrive, AGIA’s private shuttle will be waiting to escort your group on the short journey to your residence
    `
    }
  },
  {
    id: 3,
    title: 'How far away are good fishing and diving spots?',
    contents: {
      __html: `
      • The island presents unparalleled dive opportunities with a captivating combination of shallow coral reefs and colorful walls. With minimal currents along the reefs, many dives are easy enough for beginners, while several shallow sunken ships are an unforgettable introduction to wreck diving. For the more advanced diver and thrill seeker, the islands offer several blue holes, underwater cave systems and shark feeds.
      <br/>
      • Easy access to some of the world’s most celebrated inshore and deep-water fishing mean that no day on the water will ever be the same. The elusive Bahamian bonefish can be tracked along the island’s flats, while legendary offshore fishing turns up prize marlin, tuna, wahoo and more. For those looking for the ultimate sea-to-table meal, AGIA’s waterfront is the perfect spot to free dive for spiny lobster.
`
    }
  }
]
