import React from 'react'

type Props = {
  color?: string
}

const Agia: React.FC<Props> = ({ color = 'agia-primary' }) => {
  return (
    <div
      className={`text-${color} w-full font-Montserrat font-semibold tracking-[1rem] text-center text-5xl lg:text-7xl  2xl:text-7xl scale-y-[.75]`}
    >
      <p className='pl-[1rem]'>AGIA</p>
    </div>
  )
}
export default Agia
