import React from 'react'
import { GoArrowDown, GoArrowRight } from 'react-icons/go'
import NFTDiagram from './NFTDiagram'
import useWindowSize from '../utils/useWindowSize'

const NFTUtilDiagram: React.FC = () => {
  const { width } = useWindowSize()
  return (
    <div className='flex flex-col md:flex-row justify-center items-center my-6 md:mb-10'>
      <NFTDiagram message='Allowlist NFT' bgImg='bg-nftDiagram1' />
      {width < 768 ? (
        <GoArrowDown className='text-arrow-color scale-x-[300%] scale-y-[130%] text-[3rem] z-10' />
      ) : (
        <GoArrowRight className='text-arrow-color scale-x-[120%] scale-y-[150%] text-[3rem] md:text-[5rem] lg-[8rem] xl:[10rem] z-10 mx-4' />
      )}
      <NFTDiagram message='Right To Ownership NFT' bgImg='bg-nftDiagram2' />
      {width < 768 ? (
        <GoArrowDown className='text-arrow-color scale-x-[300%] scale-y-[130%] text-[3rem] z-10' />
      ) : (
        <GoArrowRight className='text-arrow-color scale-x-[120%] scale-y-[150%] text-[3rem] md:text-[5rem] lg-[8rem] xl:[10rem] z-10 mx-4' />
      )}
      <NFTDiagram message='AGIA KEY' bgImg='bg-agiaKey' />
    </div>
  )
}
export default NFTUtilDiagram
