import React from 'react'
import PrimaryButton from '../PrimaryButton'
import introHeroImg from '../../assets/images/introHeroImg.jpg'
import introSectionCouple from '../../assets/images/introSectionCouple.jpg'
import introSectionBoat from '../../assets/images/introSectionBoat.jpg'
import { useNavigate } from 'react-router-dom'
import { LandingHeaderText, LandingParagraphText } from '../AgiaTextFonts'
import CountDownBar from '../CountDownBar'
import LiteYouTubeEmbed from 'react-lite-youtube-embed';

const IntroSection: React.FC = () => {
  const navigate = useNavigate()
  const handleDownload = () => {
    (window as any).gtag('event', 'select_content', {content_type: 'brochure', item_id: 'discovery_brochure' });
    window.open(
      'https://koi-3qvul5rg5w.marketingautomation.services/net/m?md=%2BO0iCH9gRNgeAb8dDTDNOyprSoib5ot2',
      '_blank',
      'noreferrer'
    )
  }
  const handleDownload2 = () => {
    (window as any).gtag('event', 'select_content', {content_type: 'brochure', item_id: 'lifestyle_brochure' });
    window.open(
      'https://koi-3qvul5rg5w.marketingautomation.services/net/m?md=Jw7EexOzRF9V1K8FHyx5J8CHaIiLTvC%2B',
      '_blank',
      'noreferrer'
    )
  }
  const handleNavigate = () => {
    navigate('/gallery')
  }
  return (
    <section className='pb-4 w-full h-full  image-gradient-transparent'>
      <figure className='flex items-center justify-center h-full w-full py-5 '>
        <CountDownBar date={'10/8/2022 23:59:59'} />
      </figure>
      <div className='flex flex-col px-4 md:px-0 mt-4 lg:mt-12 xl:mt-16 2xl:mt-32'>
        <div className='md:flex lg:justify-between  lg:max-h-full  md:gap-8 lg:gap-12'>
          <figure className='md:max-w-md md:mr-4 lg:max-h-[50%] lg:max-w-[50%]'>
            <img
              className='md:w-full md:h-full md:object-cover'
              src={introHeroImg}
              alt={'Bahamas Island'}
            />
          </figure>
          <div className='flex flex-col items-center md:items-start justify-center md:mr-4 lg:mr-12 md:max-w-[50%] content-center md:gap-4'>
            <LandingHeaderText text='INTRODUCING AGIA' extraClassNames='mt-4 mb-2 md:m-0' />
            <h2 className='text-agia-primary font-AjensonPro my-4 xl:w-[75%] text-md lg:text-xl lg:pr-24 lg:mt-4 2xl:text-3xl  2xl:mt-8 max-w-2xl'>
              A crypto-native luxury community on Great Exuma, Bahamas.
            </h2>
            <div className='xl:w-[75%]'>
              <LandingParagraphText
                text={`
              Where an NFT unlocks Bahamas island life as it was meant to be. Where every residence
              is ocean, beach, or harbour-front. A place, as its name suggests, grounded in the
              simple, soulful aesthetic of the Greek Mediterranean – but pulsing with the
              possibility of our decentralized future.
              `}
              />
            </div>
            <PrimaryButton message={'DOWNLOAD DISCOVERY BROCHURE'} onClick={handleDownload} wide />
            <PrimaryButton message={'DOWNLOAD LIFESTYLE BROCHURE'} onClick={handleDownload2} wide />
            <PrimaryButton message={'VIEW COLLECTION'} onClick={handleNavigate} wide />
          </div>
        </div>
        <div className='hidden md:block md:px-10 md:mt-16 xl:px-40 xl:mt-32 2xl:px-80 3xl:mx-40'>
          <LiteYouTubeEmbed 
              id="HO1eLoCeZhM"
              title="AGIA Founders Video"
              noCookie={false}
              adNetwork={true}
              wrapperClass="yt-lite yt-lite-desktop"
              params="hd=1&rel=0&controls=0&fs=0&iv_load_policy=3&modestbranding=1"
              poster="maxresdefault"
              rel="none"
          />
        </div>
        <div className='block md:hidden mt-4'>
          <LiteYouTubeEmbed 
              id="FHqVlAcMj9I"
              title="AGIA Founders Video"
              noCookie={false}
              adNetwork={true}
              wrapperClass="yt-lite yt-lite-mobile"
              params="hd=1&rel=0&controls=0&fs=0&iv_load_policy=3&modestbranding=1"
              poster="maxresdefault"
              rel="none"
          />
        </div>
        <div className='flex-1 md:flex md:justify-between gap-12 md:my-12 lg:my-24 xl:my-32'>
          <div className='my-6 md:my-0 md:flex md:items-center md:justify-center'>
            <figure className='lg:ml-24 md:min-w-[15rem] md:min-h-[15rem] md:w-[20%] md:h-[22%] md:max-w-[25rem] md:max-h-[25rem] lg:max-w-[50rem] lg:max-h-[50rem] lg:w-[30vw] lg:h-[30vw] 2xl:w-[30vw] 2xl:h-[30vw]'>
              <img
                className='w-[70vw] h-[70vw] md:w-full md:h-full object-cover object-center m-auto'
                src={introSectionCouple}
                alt={'Person Fishing'}
              />
            </figure>
          </div>
          <figure className='max-h-lg md:max-max-w-full md:max-h-full md:w-[60vw] md:h-[65vw] lg:max-h-[50%] lg:max-w-[50%]'>
            <img
              className='w-full h-full  object-cover object-center'
              src={introSectionBoat}
              alt={'Bahamas Island'}
            />
          </figure>
        </div>
      </div>
    </section>
  )
}
export default IntroSection
