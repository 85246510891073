import React from 'react'
import linkIconOne from '../assets/images/instagram.png'
import linkIconTwo from '../assets/images/twitter.png'
import linkIconThree from '../assets/img/Discord.png'

function Footer() {
  return (
    <div className='bg-hidden md:bg-waterTexture bg-cover relative  w-full'>
      <div className='bg-waterTexture bg-cover'>
        <div className='bg-gradient-to-b from-white to-white/80 md:to-white/70 flex-col justify-center w-full h-fit py-8 px-10 md:px-32'>
          <div className='flex flex-row items-center justify-center'>
            <a target='_blank' href='https://discord.gg/agialiving' rel='noreferrer'>
              <img src={linkIconThree} className='h-8 w-10 lg:h-10 lg:w-12  mx-4' alt='link' />
            </a>
            <a
              target='_blank'
              href='https://instagram.com/agialiving'
              rel='noreferrer'
            >
              <img src={linkIconOne} className='w-10 h-10 lg:w-14 lg:h-14 mx-4' alt='link' />
            </a>
            <a
              target='_blank'
              href='https://twitter.com/agialiving'
              rel='noreferrer'
            >
              <img
                src={linkIconTwo}
                className='w-10 h-10 lg:w-12 lg:h-12 2xl:w-12 2xl:h-12 mx-4'
                alt='link'
              />
            </a>
          </div>
        </div>
      </div>
      <div className='md:block h-5 lg:h-5 w-full bg-gradient-to-t from-white to-transparent absolute bottom-0'></div>
    </div>
  )
}

export default Footer
