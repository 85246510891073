import React from 'react'
import { useNavigate } from 'react-router-dom'
import Logo from '../Logo'
import WalletButton from '../WalletButton'

type Props = {
  logoColor?: string
  walletBtnColor?: string
}

const Header: React.FC<Props> = ({ logoColor, walletBtnColor }) => {
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate('/')
  }
  return (
    <div className='flex flex-col-reverse md:flex-row items-center justify-center w-full px-4 my-4'>
      <div className='md:flex-1 md:flex justify-center'></div>
      <button onClick={handleNavigate} className='flex md:flex-1 justify-center items-center'>
        <Logo color={logoColor} />
      </button>
      <div className='w-[60%] text-center flex-1 md:flex md:self-start justify-center md:justify-end lg:justify-center items-center'>
        {/* <WalletButton color={walletBtnColor} /> */}
      </div>
    </div>
  )
}
export default Header
