import React, { MouseEvent } from 'react'
import { BsArrowDownCircle } from 'react-icons/bs'
import { MdOutlineAdd } from 'react-icons/md'

export type Props = {
  id: string
  label: string
  image?: string
  plans?: boolean
  onClick?: (e: MouseEvent<HTMLDivElement>) => void
}

const ShowcaseCard: React.FC<Props> = ({ id, label, image, plans, onClick }) => {
  return (
    <div
      id={id}
      onClick={onClick}
      className='cursor-pointer flex flex-col md:w-full  h-[50vw] max-h-[25rem] xl:max-h-[30rem] 2xl:max-h-[50rem] my-4'
    >
      {plans ? (
        <figure
          className='pointer-events-none bg-[#bfbfbf] bg-cover bg-center h-full flex items-center justify-end p-4'
          style={{ backgroundImage: image && `url(${image})` }}
        >
          <div className='flex-1'></div>
          <div className='flex-grow flex items-center justify-center'>
            <button className='uppercase full h-[10vw] w-[35vw] md:w-[15vw] md:h-[4vw] text-white bg-[#3b3b3b] px-4 text-[2vw] md:text-[1vw] lg:text-base xl:text-lg'>
              {label}
            </button>
          </div>
          <div className='flex-1 text-2xl self-end '>
            <BsArrowDownCircle style={{ marginLeft: 'auto' }} color='white' size={'2rem'} />
          </div>
        </figure>
      ) : (
        <figure
          className='pointer-events-none bg-[#bfbfbf] bg-cover bg-center h-full flex flex-col items-end justify-end'
          style={{ backgroundImage: image && `url(${image})` }}
        >
          <MdOutlineAdd className='text-white' size='2rem' />
        </figure>
      )}
      {/* <div className='pointer-events-none flex items-center justify-center py-2 bg-agia'>
        <p className='font-AjensonPro text-sm md:text-base lg:text-xl xl:text-2xl'>{label}</p>
      </div> */}
    </div>
  )
}
export default ShowcaseCard
