import React from 'react'
import Form from '../Form'
import Footer from '../Footer'
import { LandingHeaderText } from '../AgiaTextFonts'
import Agia from '../Agia'

function FormAndFooter() {
  return (
    <div className='bg-formBackground bg-cover bg-top w-full'>
      <div className='flex flex-col items-center justify-center'>
        <LandingHeaderText
          text='Discover More'
          extraClassNames='py-10 md:py-20 px-2'
          fontColor='text-agia'
        />
        {/* <div className='klaviyo-form-VkqwLr mb-12 md:mb-28'></div> */}
        <Form fields={['First Name', 'Last Name', 'Email', 'Message']} />
        <Footer />
      </div>
      <div className='w-full h-20 xl:h-28 bg-white text-center text-title-blue flex flex-col justify-center pb-6'>
        <Agia color='text-title-blue' />
      </div>
      <div className='w-full h-20 xl:h-28 bg-white text-center text-white bg-black/40 flex flex-col justify-center'>
        <p className='px-1 md:px-16'>
          © 2021-2022. AGIA International, Ltd. All rights reserved. All other logos, company names,
          and product names are the property of their respective owners.
        </p>
        <p className='px-1 md:px-16'>
          Site plan, along with amenities in phase 1 and 2, are subject to change based on final
          design and construction. Beach shown in front of Ocean Pavilions is to be installed during
          construction of phase 1.
        </p>
      </div>
    </div>
  )
}

export default FormAndFooter
