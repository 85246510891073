import React, {useEffect} from 'react'
import IntroSection from '../components/LandingPage/IntroSection'
import FormAndFooterSection from '../components/LandingPage/FormAndFooterSection'
import WelcomeSection from '../components/LandingPage/WelcomeSection'
import NFTUtilSection from '../components/LandingPage/NFTUtilSection'
import ConstructionSection from '../components/LandingPage/ConstructionSection'
import MiddleInfoSection from '../components/LandingPage/MiddleInfoSection'
import FAQSection from '../components/LandingPage/FAQSection'
import Carousel from '../components/ImageCarousel'

const LandingPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Agia ';
  }, []);


  return (
    <div>
      <WelcomeSection />
      <IntroSection />
      <NFTUtilSection />
      <MiddleInfoSection />
      <Carousel />
      <ConstructionSection />
      <FAQSection />
      <FormAndFooterSection />
    </div>
  )
}

export default LandingPage
