import { useMemo } from 'react'
import { useEthers } from '@usedapp/core'
import { Contract } from '@ethersproject/contracts'
import { utils } from 'ethers'
import firstStage from '../assets/abis/firstStage.json'
import secondStage from '../assets/abis/secondStage.json'

const FIRST_STAGE_ABI = new utils.Interface(firstStage)
const SECOND_STAGE_ABI = new utils.Interface(secondStage)

const FIRST_STAGE_CONTRACT = '0x012ca208a8EAC53Ad97d701AF4ee4D8E4f0d9620'
const SECOND_STAGE_CONTRACT = '0xe85482E662B83e7b7f052F5702Dfa5D585FD9B78'

export const useWhitelistContract = () => {
  const { library } = useEthers()

  return useMemo(() => {
    return new Contract(FIRST_STAGE_CONTRACT, FIRST_STAGE_ABI, library)
  }, [library])
}

export const useMainContract = () => {
  const { library } = useEthers()

  return useMemo(() => {
    return new Contract(SECOND_STAGE_CONTRACT, SECOND_STAGE_ABI, library)
  }, [library])
}
