interface estateData {
  id?: number
  sold: boolean
  estateShowcaseImgData: estateShowcaseImgData[]
  lotEastImage?: string
  lotSouthImage?: string
  floorPlan: { image?: string; link: string }
  lotPlan: { image?: string; link?: string }
  estateImage: string
  estateName: string
  price: string
  keyDetails: { content: string; style?: string }[]
  furtherDetails: { content: string }[]
  mapImage?: string
}

// furtherDetails: [
//   { content: '• Construction Ready Site ' },
//   { content: '• Direct Oceanfront to Inner Harbour' },
//   { content: '• .866 Acres' },
//   { content: '• 37,725 sf upland' },
//   { content: '• 3,000 sf deeded basin' },
//   { content: '• 130’ Private Beach' },
//   { content: '• 150’ Harbour Front with 150’ of Custom Seawall currently installed' },
//   { content: '• 100’ Custom IPE Hardwood Decked Floating' },
//   { content: '• 300’ Lot Depth' },
//   { content: '• 15’ Lot Elevation' }
// ]

interface estateShowcaseImgData {
  id: string
  image: string
  label: string
}

interface allEstateData {
  [key: string]: estateData
}

const EstateData: allEstateData = {
  '1': {
    id: 1,
    sold: false,
    estateImage: 'Villa_01_WL_NFT.jpg',
    estateName: 'Oceanfront Villa 1',
    price: '$10,000',
    lotEastImage: 'Villa1_2A9A0402.jpg',
    lotSouthImage: '',
    floorPlan: {
      link: 'villa_lot_1_plans.pdf',
      image: 'villa_lot_1_plans-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Villa_Homesite_1.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'estateShowcaseImgLast.jpg'
      },
      {
        id: '1',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg1.jpg'
      },
      {
        id: '2',
        label: 'Lorem ipsum dolar sit amet',
        image: 'carouselImg4.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 4-6 Bedroom'
      },
      {
        content: '• 4,500-6,500 sf custom design option'
      },
      {
        content: '• Lot Size 37,723 sf + 2,960 sf deeded basin (40,683 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $2,795,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Direct Oceanfront to Inner Harbour'
      },
      {
        content: '• .866 Acres'
      },
      {
        content: '• 37,723 sf upland'
      },
      {
        content: '• 2,960 sf deeded basin'
      },
      {
        content: '• 130’ Private Beach'
      },
      { content: '• 148’ x 20’ Harbour / Seawall Frontage' },
      {
        content: '• 100’ Custom IPE Hardwood Decked Floating Dock Included by Developer'
      },
      {
        content: '• 300’ Lot Depth'
      },
      {
        content: '• 13’ Lot Elevation'
      }
    ]
  },
  '2': {
    id: 2,
    sold: true,
    estateImage: 'Villa_02_WL_NFT.jpg',
    estateName: 'Oceanfront Villa 2',
    price: '$10,000',
    lotEastImage: 'Villa2_2A9A0393.jpg',
    lotSouthImage: '',
    floorPlan: {
      link: 'villa_lot_2_plans.pdf',
      image: 'villa_lot_2_plans-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Villa_Homesite_2.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'estateShowcaseImgLast.jpg'
      },
      {
        id: '1',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg1.jpg'
      },
      {
        id: '2',
        label: 'Lorem ipsum dolar sit amet',
        image: 'carouselImg4.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 4-6 Bedroom'
      },
      {
        content: '• 4,500-6,500 sf custom design option'
      },
      {
        content: '• Lot Size 41,462 sf + 2,500 sf deeded basin (43,962 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $3,995,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Direct Oceanfront to Inner Harbour'
      },
      {
        content: '• .951 Acres'
      },
      {
        content: '• 41,462 sf upland'
      },
      {
        content: '• 2,500 sf deeded basin'
      },
      {
        content: '• 120’ Private Beach'
      },
      { content: '• 125’ x 20’ Harbour / Seawall Frontage' },

      {
        content: '• 100’ Custom IPE Hardwood Decked Floating Dock Included by Developer'
      },
      {
        content: '• Includes recently build 5000’ sf 5 bed / 4 bath beachfront home, "Blue Nirvana"'
      },
      {
        content: '• 360’ Lot Depth'
      },
      {
        content: '• 13’ Lot Elevation'
      }
    ]
  },
  '3': {
    id: 3,
    sold: false,
    estateImage: 'Villa_03_WL_NFT.jpg',
    estateName: 'Oceanfront Villa 3',
    price: '$10,000',
    lotEastImage: 'Villa3_2A9A0389.jpg',
    lotSouthImage: '',
    floorPlan: {
      link: 'villa_lot_3_plans.pdf',
      image: 'villa_lot_3_plans-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Villa_Homesite_3.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'estateShowcaseImgLast.jpg'
      },
      {
        id: '1',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg1.jpg'
      },
      {
        id: '2',
        label: 'Lorem ipsum dolar sit amet',
        image: 'carouselImg4.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 4-6 Bedroom'
      },
      {
        content: '• 4,500-6,500 sf custom design option'
      },
      {
        content: '• Lot Size 41,462 sf + 2,400 sf deeded basin (43,862 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $2,895,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Direct Oceanfront to Inner Harbour'
      },
      {
        content: '• .951 Acres'
      },
      {
        content: '• 41,462 sf upland'
      },
      {
        content: '• 2,400 sf deeded basin'
      },
      {
        content: '• 120’ Private Beach'
      },
      { content: '• 120’ x 20’ Harbour / Seawall Frontage' },

      {
        content: '• 100’ Custom IPE Hardwood Decked Floating Dock Included by Developer'
      },
      {
        content: '• 360’ Lot Depth'
      },
      {
        content: '• 13’ Lot Elevation'
      }
    ]
  },
  '4': {
    id: 4,
    sold: false,
    estateImage: 'Villa_04_WL_NFT.jpg',
    estateName: 'Oceanfront Villa 4',
    price: '$10,000',
    lotEastImage: 'Villa4_2A9A0390.jpg',
    lotSouthImage: '',
    floorPlan: {
      link: 'villa_lot_4_plans.pdf',
      image: 'villa_lot_4_plans-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Villa_Homesite_4.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'estateShowcaseImgLast.jpg'
      },
      {
        id: '1',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg1.jpg'
      },
      {
        id: '2',
        label: 'Lorem ipsum dolar sit amet',
        image: 'carouselImg4.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 4-6 Bedroom'
      },
      {
        content: '• 4,500-6,500 sf custom design option'
      },
      {
        content: '• Lot Size 41,311 sf + 2,600 sf deeded basin (43,911 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $2,895,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Direct Oceanfront to Inner Harbour'
      },
      {
        content: '• .948 Acres'
      },
      {
        content: '• 41,311 sf upland'
      },
      {
        content: '• 2,600 sf deeded basin'
      },
      {
        content: '• 120’ Private Beach'
      },
      { content: '• 130’ x 20’ Harbour / Seawall Frontage' },

      {
        content: '• 100’ Custom IPE Hardwood Decked Floating Dock Included by Developer'
      },
      {
        content: '• 350’ Lot Depth'
      },
      {
        content: '• 13’ Lot Elevation'
      }
    ]
  },
  '5': {
    id: 5,
    sold: false,
    estateImage: 'Villa_05_WL_NFT.jpg',
    estateName: 'Oceanfront Villa 5',
    price: '$10,000',
    lotEastImage: 'Villa5_2A9A0386.jpg',
    lotSouthImage: '',
    floorPlan: {
      link: 'villa_lot_5_plans.pdf',
      image: 'villa_lot_5_plans-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Villa_Homesite_5.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'estateShowcaseImgLast.jpg'
      },
      {
        id: '1',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg1.jpg'
      },
      {
        id: '2',
        label: 'Lorem ipsum dolar sit amet',
        image: 'carouselImg4.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 4-6 Bedroom'
      },
      {
        content: '• 4,500-6,500 sf custom design option'
      },
      {
        content: '• Lot Size 41,141 sf + 2,400 sf deeded basin (43,541 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $2,895,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Direct Oceanfront to Inner Harbour'
      },
      {
        content: '• .944 Acres'
      },
      {
        content: '• 41,141 sf upland'
      },
      {
        content: '• 2,400 sf deeded basin'
      },
      {
        content: '• 120’ Private Beach'
      },
      { content: '• 120’ x 20’ Harbour / Seawall Frontage' },

      {
        content: '• 100’ Custom IPE Hardwood Decked Floating Dock Included by Developer'
      },
      {
        content: '• 430’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '6': {
    id: 6,
    sold: false,
    estateImage: 'Villa_06_WL_NFT.jpg',
    estateName: 'Oceanfront Villa 6',
    price: '$10,000',
    lotEastImage: 'Villa6_2A9A0382.jpg',
    lotSouthImage: '',
    floorPlan: {
      link: 'villa_lot_6_plans.pdf',
      image: 'villa_lot_6_plans-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Villa_Homesite_6.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'estateShowcaseImgLast.jpg'
      },
      {
        id: '1',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg1.jpg'
      },
      {
        id: '2',
        label: 'Lorem ipsum dolar sit amet',
        image: 'carouselImg4.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 4-6 Bedroom'
      },
      {
        content: '• 4,500-6,500 sf custom design option'
      },
      {
        content: '• Lot Size 45,318 sf + 2,200 sf deeded basin (47,518 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $3,495,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Direct Oceanfront to Inner Harbour'
      },
      {
        content: '• 1.04 Acres'
      },
      {
        content: '• 45,318 sf upland'
      },
      {
        content: '• 2,200 sf deeded basin'
      },
      {
        content: '• 112’ Private Beach'
      },
      { content: '• 110’ x 20’ Harbour / Seawall Frontage' },

      {
        content: '• 100’ Custom IPE Hardwood Decked Floating Dock Included by Developer'
      },
      {
        content: '• 375’ Lot Depth'
      },
      {
        content: '• 15’ Lot Elevation'
      }
    ]
  },
  '7': {
    id: 7,
    sold: false,
    estateImage: 'Villa_07_WL_NFT.jpg',
    estateName: 'Oceanfront Villa 7',
    price: '$10,000',
    lotEastImage: 'Villa7_2A9A0379.jpg',
    lotSouthImage: '',
    floorPlan: {
      link: 'villa_lot_7_plans.pdf',
      image: 'villa_lot_7_plans-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Villa_Homesite_7.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'estateShowcaseImgLast.jpg'
      },
      {
        id: '1',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg1.jpg'
      },
      {
        id: '2',
        label: 'Lorem ipsum dolar sit amet',
        image: 'carouselImg4.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 4-6 Bedroom'
      },
      {
        content: '• 4,500-6,500 sf custom design option'
      },
      {
        content: '• Lot Size 41,321 sf + 2,300 sf deeded basin (43,621 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $2,895,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Direct Oceanfront to Inner Harbour'
      },
      {
        content: '• .948 Acres'
      },
      {
        content: '• 41,321 sf upland'
      },
      {
        content: '• 2,300 sf deeded basin'
      },
      {
        content: '• 120’ Private Beach'
      },
      { content: '• 115’ x 20’  Harbour / Seawall Frontage' },

      {
        content: '• 100’ Custom IPE Hardwood Decked Floating Dock Included by Developer'
      },
      {
        content: '• 355’ Lot Depth'
      },
      {
        content: '• 17’ Lot Elevation'
      }
    ]
  },
  '8': {
    id: 8,
    sold: false,
    estateImage: 'Villa_08_WL_NFT.jpg',
    estateName: 'Oceanfront Villa 8',
    price: '$10,000',
    lotEastImage: 'Villa8_2A9A0369.jpg',
    lotSouthImage: '',
    floorPlan: {
      link: 'villa_lot_8_plans.pdf',
      image: 'villa_lot_8_plans-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Villa_Homesite_8.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'estateShowcaseImgLast.jpg'
      },
      {
        id: '1',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg1.jpg'
      },
      {
        id: '2',
        label: 'Lorem ipsum dolar sit amet',
        image: 'carouselImg4.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 4-6 Bedroom'
      },
      {
        content: '• 4,500-6,500 sf custom design option'
      },
      {
        content: '• Lot Size 41,765 sf + 2,600 sf deeded basin (44,365 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $2,950,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Direct Oceanfront to Inner Harbour'
      },
      {
        content: '• .958 Acres'
      },
      {
        content: '• 41,765 sf upland'
      },
      {
        content: '• 2,600 sf deeded basin'
      },
      {
        content: '• 135’ Private Beach'
      },
      { content: '• 130’ x 20’ Harbour / Seawall Frontage' },

      {
        content: '• 100’ Custom IPE Hardwood Decked Floating Dock Included by Developer'
      },
      {
        content: '• 345’ Lot Depth'
      },
      {
        content: '• 17’ Lot Elevation'
      }
    ]
  },
  '9': {
    id: 9,
    sold: false,
    estateImage: 'Villa_09_WL_NFT.jpg',
    estateName: 'Oceanfront Villa 9',
    price: '$10,000',
    lotEastImage: 'Villa9_2A9A0374.jpg',
    lotSouthImage: '',
    floorPlan: {
      link: 'villa_lot_9_plans.pdf',
      image: 'villa_lot_9_plans-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Villa_Homesite_9.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'estateShowcaseImgLast.jpg'
      },
      {
        id: '1',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg1.jpg'
      },
      {
        id: '2',
        label: 'Lorem ipsum dolar sit amet',
        image: 'carouselImg4.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 4-6 Bedroom'
      },
      {
        content: '• 4,500-6,500 sf custom design option'
      },
      {
        content: '• Lot Size 45,122 sf + 2,700 sf deeded basin (47,822 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $3,150,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Direct Oceanfront to Inner Harbour'
      },
      {
        content: '• 1.035 Acres'
      },
      {
        content: '• 45,122 sf upland'
      },
      {
        content: '• 2,700 sf deeded basin'
      },
      {
        content: '• 165’ Private Beach'
      },
      { content: '• 135’ x 20’  Harbour / Seawall Frontage' },

      {
        content: '• 80’ Custom IPE Hardwood Decked Floating Dock Included by Developer'
      },
      {
        content: '• 385’ Lot Depth'
      },
      {
        content: '• 17’ Lot Elevation'
      }
    ]
  },
  '10': {
    id: 10,
    sold: false,
    estateImage: 'Villa_10_WL_NFT.jpg',
    estateName: 'Oceanfront Villa 10',
    price: '$10,000',
    lotEastImage: 'Villa10_2A9A0350.jpg',
    lotSouthImage: '',
    floorPlan: {
      link: 'villa_lot_10_plans.pdf',
      image: 'villa_lot_10_plans-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Villa_Homesite_10.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'estateShowcaseImgLast.jpg'
      },
      {
        id: '1',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg1.jpg'
      },
      {
        id: '2',
        label: 'Lorem ipsum dolar sit amet',
        image: 'carouselImg4.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 4-6 Bedroom'
      },
      {
        content: '• 4,500-6,500 sf custom design option'
      },
      {
        content: '• Lot Size 47,645 sf + 2,200 sf deeded basin (SOLD)'
      },
      {
        content: '• Right to Ownership NFT starting at: $SOLD ',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Direct Oceanfront to Inner Harbour'
      },
      {
        content: '• 1.093 Acres'
      },
      {
        content: '• 47,645 sf upland'
      },
      {
        content: '• 2,200 sf deeded basin'
      },
      {
        content: '• 135’ Private Beach'
      },
      { content: '• 110’ x 20’ Harbour / Seawall Frontage' },

      {
        content: '• 100’ Custom IPE Hardwood Decked Floating Dock Included by Developer'
      },
      {
        content: '• 450’ Lot Depth'
      },
      {
        content: '• 21’ Lot Elevation'
      }
    ]
  },
  '11': {
    id: 11,
    sold: false,
    estateImage: 'Villa_11_WL_NFT.jpg',
    estateName: 'Oceanfront Villa 11',
    price: '$10,000',
    lotEastImage: 'Villa11_2A9A0348.jpg',
    lotSouthImage: '',
    floorPlan: {
      link: 'villa_lot_11_plans.pdf',
      image: 'villa_lot_11_plans-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Villa_Homesite_11.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'estateShowcaseImgLast.jpg'
      },
      {
        id: '1',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg1.jpg'
      },
      {
        id: '2',
        label: 'Lorem ipsum dolar sit amet',
        image: 'carouselImg4.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 4-6 Bedroom'
      },
      {
        content: '• 4,500-6,500 sf custom design option'
      },
      {
        content: '• Lot Size 48,580 sf + 1,400 sf deeded basin (49,980 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $2,995,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Direct Oceanfront to Inner Harbour'
      },
      {
        content: '• 1.115 Acres'
      },
      {
        content: '• 48,580 sf upland'
      },
      {
        content: '• 1,400 sf deeded basin'
      },
      {
        content: '• 160’ Private Beach'
      },
      { content: '• 70’ x 20’  Harbour / Seawall Frontage' },

      {
        content: '• 70’ Custom IPE Hardwood Decked Floating Dock Included by Developer'
      },
      {
        content: '• 425’ Lot Depth'
      },
      {
        content: '• 26’ Lot Elevation'
      }
    ]
  },
  '12': {
    id: 12,
    sold: false,
    estateImage: 'Villa_12_WL_NFT.jpg',
    estateName: 'Oceanfront Villa 12',
    price: '$10,000',
    lotEastImage: 'Villa12_2A9A0347.jpg',
    lotSouthImage: '',
    floorPlan: {
      link: 'villa_lot_12_plans.pdf',
      image: 'villa_lot_12_plans-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Villa_Homesite_12.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'estateShowcaseImgLast.jpg'
      },
      {
        id: '1',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg1.jpg'
      },
      {
        id: '2',
        label: 'Lorem ipsum dolar sit amet',
        image: 'carouselImg4.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 4-6 Bedroom'
      },
      {
        content: '• 4,500-6,500 sf custom design option'
      },
      {
        content: '• Lot Size 42,924 sf + 1,400 sf deeded basin (44,324 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $2,995,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Direct Oceanfront to Inner Harbour'
      },
      {
        content: '• 0.985 Acres'
      },
      {
        content: '• 42,924 sf upland'
      },
      {
        content: '• 1,400 sf deeded basin'
      },
      {
        content: '• 155’ Private Beach'
      },
      { content: '• 70’ x 20’  Harbour / Seawall Frontage' },

      {
        content: '• 70’ Custom IPE Hardwood Decked Floating Dock Included by Developer'
      },
      {
        content: '• 425’ Lot Depth'
      },
      {
        content: '• 28’ Lot Elevation'
      }
    ]
  },
  '13': {
    id: 13,
    sold: false,
    estateImage: 'Villa_13_WL_NFT.jpg',
    estateName: 'Oceanfront Villa 13',
    price: '$10,000',
    lotEastImage: 'Villa13_2A9A0342.jpg',
    lotSouthImage: '',
    floorPlan: {
      link: 'villa_lot_13_plans.pdf',
      image: 'villa_lot_13_plans-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Villa_Homesite_13.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'estateShowcaseImgLast.jpg'
      },
      {
        id: '1',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg1.jpg'
      },
      {
        id: '2',
        label: 'Lorem ipsum dolar sit amet',
        image: 'carouselImg4.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 4-6 Bedroom'
      },
      {
        content: '• 4,500-6,500 sf custom design option'
      },
      {
        content: '• Lot Size 64,512 sf + 3,200 sf deeded basin (67,712 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $4,995,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Direct Oceanfront to Inner Harbour'
      },
      {
        content: '• 1.481 Acres'
      },
      {
        content: '• 64,512 sf upland'
      },
      {
        content: '• 3,200 sf deeded basin'
      },
      {
        content: '• 180’ Private Beach'
      },
      { content: '• 160’ x 20’ Harbour / Seawall Frontage  (600’ of Overall Seawall Total) ' },
      {
        content: '• 100’ Custom IPE Hardwood Decked Floating Dock Included by Developer'
      },
      {
        content: '• Includes recently built 5000’ sf 4bed / 4bath residence, "Stargazer"'
      },
      {
        content: '• 550’ Lot Depth'
      },
      {
        content: '• 30’ Lot Elevation'
      }
    ]
  },
  '14': {
    id: 14,
    sold: false,
    estateImage: 'Villa_14_WL_NFT.jpg',
    estateName: 'Harbour Front Villa 14',
    price: '$10,000',
    lotEastImage: 'Villa14_2A9A0481.jpg',
    lotSouthImage: '',
    floorPlan: {
      link: 'villa_lot_14_plans.pdf',
      image: 'villa_lot_14_plans-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Villa_14.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'estateShowcaseImgLast.jpg'
      },
      {
        id: '1',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg1.jpg'
      },
      {
        id: '2',
        label: 'Lorem ipsum dolar sit amet',
        image: 'carouselImg4.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 4-6 Bedroom'
      },
      {
        content: '• 4,500-6,500 sf custom design option'
      },
      {
        content: '• Lot Size 64,338 sf + 9,700 sf deeded basin (74,038 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $2,995,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Direct Harbour to Preserve'
      },
      {
        content: '• 64,338 sf upland'
      },
      {
        content: '• 9,700 sf deeded basin'
      },
      {
        content: '• 485’ Harbour/Seawall Frontage'
      },
      {
        content: '• 100’ Custom IPE Hardwood Decked Floating Dock Included by Developer'
      },
      {
        content: '• 450’ Lot Depth'
      },
      {
        content: '• 18’ Lot Elevation'
      }
    ]
  },
  '15': {
    id: 15,
    sold: false,
    estateImage: 'Villa_15_WL_NFT.jpg',
    estateName: 'Harbour Front Villa 15',
    price: '$10,000',
    lotEastImage: 'Villa15_2A9A0486.jpg',
    lotSouthImage: '',
    floorPlan: {
      link: 'villa_lot_15_plans.pdf',
      image: 'villa_lot_15_plans-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Villa_15.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'estateShowcaseImgLast.jpg'
      },
      {
        id: '1',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg1.jpg'
      },
      {
        id: '2',
        label: 'Lorem ipsum dolar sit amet',
        image: 'carouselImg4.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 4-6 Bedroom'
      },
      {
        content: '• 4,500-6,500 sf custom design option'
      },
      {
        content: '• Lot Size 57,594 sf + 9,840 sf deeded basin (67,434 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $2,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Direct Harbour to Preserve'
      },
      {
        content: '• 57,594 sf upland'
      },
      {
        content: '• 9,840 sf deeded basin'
      },
      {
        content: '• 492’ Harbour/Seawall Frontage'
      },
      {
        content: '• 100’ Custom IPE Hardwood Decked Floating Dock Included by Developer'
      },
      {
        content: '• 450’ Lot Depth'
      },
      {
        content: '• 18’ Lot Elevation'
      }
    ]
  },
  '16': {
    id: 16,
    sold: false,
    estateImage: 'Villa_16_WL_NFT.jpg',
    estateName: 'Harbour Front Villa 16',
    price: '$10,000',
    lotEastImage: 'Villa16_2A9A0510.jpg',
    lotSouthImage: 'Villa16_2A9A0517.jpg',
    floorPlan: {
      link: 'villa_lot_16_plans.pdf',
      image: 'villa_lot_16_plans-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Villa_16.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'estateShowcaseImgLast.jpg'
      },
      {
        id: '1',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg1.jpg'
      },
      {
        id: '2',
        label: 'Lorem ipsum dolar sit amet',
        image: 'carouselImg4.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 4-6 Bedroom'
      },
      {
        content: '• 4,500-6,500 sf custom design option'
      },
      {
        content: '• Lot Size 69,531 sf + 8,400 sf deeded basin (77,931 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $3,150,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Direct Harbour to Preserve'
      },
      {
        content: '• 69,531 sf upland'
      },
      {
        content: '• 8,400 sf deeded basin'
      },
      {
        content: '• 420’ Harbour/Seawall Frontage'
      },
      {
        content: '• 100’ Custom IPE Hardwood Decked Floating Dock Included by Developer'
      },
      {
        content: '• 450’ Lot Depth'
      },
      {
        content: '• 21’ Lot Elevation'
      }
    ]
  },
  '17': {
    id: 17,
    sold: false,
    estateImage: 'Villa_17_WL_NFT.jpg',
    estateName: 'Harbour Front Villa 17',
    price: '$10,000',
    lotEastImage: 'Villa17_2A9A0537.jpg',
    lotSouthImage: '',
    floorPlan: {
      link: 'villa_lot_17_plans.pdf',
      image: 'villa_lot_17_plans-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Villa_17.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'estateShowcaseImgLast.jpg'
      },
      {
        id: '1',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg1.jpg'
      },
      {
        id: '2',
        label: 'Lorem ipsum dolar sit amet',
        image: 'carouselImg4.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 4-6 Bedroom'
      },
      {
        content: '• 4,500-6,500 sf custom design option'
      },
      {
        content: '• Lot Size 79,796 sf + 9,800 sf deeded basin (89,596 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $3,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Direct Harbour to Preserve'
      },
      {
        content: '• 79,796 sf upland'
      },
      {
        content: '• 9,800 sf deeded basin'
      },
      {
        content: '• 490’ Harbour/Seawall Frontage'
      },
      {
        content: '• 100’ Custom IPE Hardwood Decked Floating Dock Included by Developer'
      },
      {
        content: '• 450’ Lot Depth'
      },
      {
        content: '• 28’ Lot Elevation'
      }
    ]
  },
  '18': {
    id: 18,
    sold: false,
    estateImage: 'Villa_18_WL_NFT.jpg',
    estateName: 'Harbour Front Villa 18',
    price: '$10,000',
    lotEastImage: 'Villa18_2A9A0549.jpg',
    lotSouthImage: 'Villa18_2A9A0561.jpg',
    floorPlan: {
      link: 'villa_lot_18_plans.pdf',
      image: 'villa_lot_18_plans-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Villa_18.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'estateShowcaseImgLast.jpg'
      },
      {
        id: '1',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg1.jpg'
      },
      {
        id: '2',
        label: 'Lorem ipsum dolar sit amet',
        image: 'carouselImg4.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 4-6 Bedroom'
      },
      {
        content: '• 4,500-6,500 sf custom design option'
      },
      {
        content: '• Lot Size 82,809 sf + 9,400 sf deeded basin (92,209 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $3,725,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Direct Harbour to Preserve'
      },
      {
        content: '• 82,809 sf upland'
      },
      {
        content: '• 9,400 sf deeded basin'
      },
      {
        content: '• 470’ Harbour/Seawall Frontage'
      },
      {
        content: '• 100’ Custom IPE Hardwood Decked Floating Dock Included by Developer'
      },
      {
        content: '• 500’ Lot Depth'
      },
      {
        content: '• 28’ Lot Elevation'
      }
    ]
  },
  '19': {
    id: 19,
    sold: false,
    estateImage: 'Pavillion_01_WL_NFT.jpg',
    estateName: 'Ocean Pavilion 1',
    price: '$10,000',
    lotEastImage: 'Pavilion1_2A9A0449.jpg',
    lotSouthImage: '',
    mapImage: 'Ocean_Pavilion_Lot_01.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Ocean_Pavilion_1.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 900 sf under A/C, 1,600 sf overall external'
      },
      {
        content: '• Lot Size 3,017 sf + 600 sf deeded basin (3,617 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,695,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanfront to Harbour'
      },
      {
        content: '• 3,017 sf upland'
      },
      {
        content: '• 600 sf deeded basin'
      },
      {
        content: '• 45’ Beach Frontage'}, {content:'• 30’ Harbour Frontage'
      },
      {
        content: '•  Oceanfront to Harbour with deeded 30’  floating dock access  '
      },
      {
        content: '• 97’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '20': {
    id: 20,
    sold: false,
    estateImage: 'Pavillion_02_WL_NFT.jpg',
    estateName: 'Ocean Pavilion 2',
    price: '$10,000',
    lotEastImage: 'Pavilion1_2A9A0449.jpg',
    lotSouthImage: 'Pavilion2_3_2A9A0448.jpg',
    mapImage: 'Ocean_Pavilion_Lot_02.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Ocean_Pavilion_2.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 900 sf under A/C, 1,600 sf overall external'
      },
      {
        content: '• Lot Size 2,961sf + 600 sf deeded basin (3,561 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,695,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanfront to Harbour'
      },
      {
        content: '• 2,961 sf upland'
      },
      {
        content: '• 600 sf deeded basin'
      },
      {
        content: '• 60’ Beach Frontage'}, {content:'• 30’ Harbour Frontage'
      },
      {
        content: '•  Oceanfront to Harbour with deeded 30’  floating dock access  '
      },
      {
        content: '• 135’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '21': {
    id: 21,
    sold: false,
    estateImage: 'Pavillion_03_WL_NFT.jpg',
    estateName: 'Ocean Pavilion 3',
    price: '$10,000',
    lotEastImage: 'Pavilion2_3_2A9A0448.jpg',
    lotSouthImage: '',
    mapImage: 'Ocean_Pavilion_Lot_03.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Ocean_Pavilion_3.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 900 sf under A/C, 1,600 sf overall external'
      },
      {
        content: '• Lot Size 3,309sf + 600 sf deeded basin (3,909 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,695,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanfront to Harbour'
      },
      {
        content: '• 3,309 sf upland'
      },
      {
        content: '• 600 sf deeded basin'
      },
      {
        content: '• 53’ Beach Frontage'}, {content:'• 30’ Harbour Frontage'
      },
      {
        content: '•  Oceanfront to Harbour with deeded 30’  floating dock access  '
      },
      {
        content: '• 135’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '22': {
    id: 22,
    estateName: 'Ocean Pavilion 4',
    sold: false,
    estateImage: 'Pavillion_04_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion4_5_2A9A0451.jpg',
    lotSouthImage: '',
    mapImage: 'Ocean_Pavilion_Lot_04.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Ocean_Pavilion_4.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 900 sf under A/C, 1,600 sf overall external'
      },
      {
        content: '• Lot Size 3,124sf + 600 sf deeded basin (3,724 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,695,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanfront to Harbour'
      },
      {
        content: '• 3,124 sf upland'
      },
      {
        content: '• 600 sf deeded basin'
      },
      {
        content: '• 53’ Beach Frontage'}, {content:'• 30’ Harbour Frontage'
      },
      {
        content: '•  Oceanfront to Harbour with deeded 30’  floating dock access  '
      },
      {
        content: '• 107’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '23': {
    id: 23,
    estateName: 'Ocean Pavilion 5',
    sold: false,
    estateImage: 'Pavillion_05_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion4_5_2A9A0451.jpg',
    lotSouthImage: '',
    mapImage: 'Ocean_Pavilion_Lot_05.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Ocean_Pavilion_5.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 900 sf under A/C, 1,600 sf overall external'
      },
      {
        content: '• Lot Size 3,177sf + 600 sf deeded basin (3,777 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,695,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanfront to Harbour'
      },
      {
        content: '• 3,177  sf upland'
      },
      {
        content: '• 600 sf deeded basin'
      },
      {
        content: '• 53’ Beach Frontage'}, {content:'• 30’ Harbour Frontage'
      },
      {
        content: '•  Oceanfront to Harbour with deeded 30’  floating dock access  '
      },
      {
        content: '• 99’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '24': {
    id: 24,
    estateName: 'Ocean Pavilion 6',
    sold: true,
    estateImage: 'Pavillion_06_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion6_7_2A9A0654.jpg',
    lotSouthImage: '',
    mapImage: 'Ocean_Pavilion_Lot_06.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Ocean_Pavilion_6.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 900 sf under A/C, 1,600 sf overall external'
      },
      {
        content: '• Lot Size 4,688sf + 600 sf deeded basin (5,288 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,695,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanfront to Harbour'
      },
      {
        content: '• 4,688  sf upland'
      },
      {
        content: '• 600 sf deeded basin'
      },
      {
        content: '• 30’ Beach Frontage'}, {content:'• 30’ Harbour Frontage'
      },
      {
        content: '•  Oceanfront to Harbour with deeded 30’  floating dock access  '
      },
      {
        content: '• 155’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '25': {
    id: 25,
    estateName: 'Ocean Pavilion 7',
    sold: true,
    estateImage: 'Pavillion_07_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion6_7_2A9A0654.jpg',
    lotSouthImage: '',
    mapImage: 'Ocean_Pavilion_Lot_07.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Ocean_Pavilion_7.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 900 sf under A/C, 1,600 sf overall external'
      },
      {
        content: '• Lot Size 4,886sf + 600 sf deeded basin (5,486 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,695,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanfront to Harbour'
      },
      {
        content: '• 4,886  sf upland'
      },
      {
        content: '• 600 sf deeded basin'
      },
      {
        content: '• 30’ Beach Frontage'}, {content:'• 30’ Harbour Frontage'
      },
      {
        content: '•  Oceanfront to Harbour with deeded 30’  floating dock access  '
      },
      {
        content: '• 160’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '26': {
    id: 26,
    estateName: 'Ocean Pavilion 8',
    sold: false,
    estateImage: 'Pavillion_08_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion8_9_2A9A0657.jpg',
    lotSouthImage: '',
    mapImage: 'Ocean_Pavilion_Lot_08.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Ocean_Pavilion_8.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 900 sf under A/C, 1,600 sf overall external'
      },
      {
        content: '• Lot Size 5,132sf + 600 sf deeded basin (5,732 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,695,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanfront to Harbour'
      },
      {
        content: '• 5,132  sf upland'
      },
      {
        content: '• 600 sf deeded basin'
      },
      {
        content: '• 31’ Beach Frontage'}, {content:'• 30’ Harbour Frontage'
      },
      {
        content: '•  Oceanfront to Harbour with deeded 30’  floating dock access  '
      },
      {
        content: '• 168’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '27': {
    id: 27,
    estateName: 'Ocean Pavilion 9',
    sold: false,
    estateImage: 'Pavillion_09_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion8_9_2A9A0657.jpg',
    lotSouthImage: '',
    mapImage: 'Ocean_Pavilion_Lot_09.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Ocean_Pavilion_9.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 900 sf under A/C, 1,600 sf overall external'
      },
      {
        content: '• Lot Size 5,390sf + 600 sf deeded basin (5,990 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,695,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanfront to Harbour'
      },
      {
        content: '• 5,390  sf upland'
      },
      {
        content: '• 600 sf deeded basin'
      },
      {
        content: '• 32’ Beach Frontage'}, {content:'• 30’ Harbour Frontage'
      },
      {
        content: '•  Oceanfront to Harbour with deeded 30’  floating dock access  '
      },
      {
        content: '• 175’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '28': {
    id: 28,
    estateName: 'Ocean Pavilion 10',
    sold: false,
    estateImage: 'Pavillion_10_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion10_11_16_17_2A9A0655.jpg',
    lotSouthImage: '',
    mapImage: 'Ocean_Pavilion_Lot_10.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Ocean_Pavilion_10.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 900 sf under A/C, 1,600 sf overall external'
      },
      {
        content: '• Lot Size 5,607sf + 600 sf deeded basin (6,207 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,695,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanfront to Harbour'
      },
      {
        content: '• 5,607  sf upland'
      },
      {
        content: '• 600 sf deeded basin'
      },
      {
        content: '• 32’ Beach Frontage'}, {content:'• 30’ Harbour Frontage'
      },
      {
        content: '•  Oceanfront to Harbour with deeded 30’  floating dock access  '
      },
      {
        content: '• 175’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '29': {
    id: 29,
    estateName: 'Ocean Pavilion 11',
    sold: false,
    estateImage: 'Pavillion_11_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion10_11_16_17_2A9A0655.jpg',
    lotSouthImage: '',
    mapImage: 'Ocean_Pavilion_Lot_11.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Ocean_Pavilion_11.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 900 sf under A/C, 1,600 sf overall external'
      },
      {
        content: '• Lot Size 5,630sf + 600 sf deeded basin (6,230 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,695,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanfront to Harbour'
      },
      {
        content: '• 5,630  sf upland'
      },
      {
        content: '• 600 sf deeded basin'
      },
      {
        content: '• 33’ Beach Frontage'}, {content:'• 30’ Harbour Frontage'
      },
      {
        content: '•  Oceanfront to Harbour with deeded 30’  floating dock access  '
      },
      {
        content: '• 178’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '30': {
    id: 30,
    estateName: 'Ocean Pavilion 12',
    sold: false,
    estateImage: 'Pavillion_12_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion12_18_19_2A9A0656.jpg',
    lotSouthImage: '',
    mapImage: 'Ocean_Pavilion_Lot_12.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Ocean_Pavilion_12.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 900 sf under A/C, 1,600 sf overall external'
      },
      {
        content: '• Lot Size 5,488sf + 600 sf deeded basin (6,088 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,695,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanfront to Harbour'
      },
      {
        content: '• 5,488  sf upland'
      },
      {
        content: '• 600 sf deeded basin'
      },
      {
        content: '• 33’ Beach Frontage'}, {content:'• 30’ Harbour Frontage'
      },
      {
        content: '•  Oceanfront to Harbour with deeded 30’  floating dock access  '
      },
      {
        content: '• 178’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '31': {
    id: 31,
    estateName: 'Ocean Pavilion 13',
    sold: false,
    estateImage: 'Pavillion_13_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion13_14_15_2A9A0661.jpg',
    lotSouthImage: '',
    mapImage: 'Ocean_Pavilion_Lot_13.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Ocean_Pavilion_13.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 900 sf under A/C, 1,600 sf overall external'
      },
      {
        content: '• Lot Size 5,283sf + 600 sf deeded basin (5,883 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanfront to Harbour'
      },
      {
        content: '• 5,283  sf upland'
      },
      {
        content: '• 600 sf deeded basin'
      },
      {
        content: '• 33’ Beach Frontage'}, {content:'• 30’ Harbour Frontage'
      },
      {
        content: '•  Oceanfront to Harbour with deeded 30’  floating dock access  '
      },
      {
        content: '• 175’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '32': {
    id: 32,
    estateName: 'Ocean Pavilion 14',
    sold: false,
    estateImage: 'Pavillion_14_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion13_14_15_2A9A0661.jpg',
    lotSouthImage: '',
    mapImage: 'Ocean_Pavilion_Lot_14.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Ocean_Pavilion_14.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 900 sf under A/C, 1,600 sf overall external'
      },
      {
        content: '• Lot Size 5,462sf + 600 sf deeded basin (6,062 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanfront to Harbour'
      },
      {
        content: '• 5,462  sf upland'
      },
      {
        content: '• 600 sf deeded basin'
      },
      {
        content: '• 32’ Beach Frontage'}, {content:'• 30’ Harbour Frontage'
      },
      {
        content: '•  Oceanfront to Harbour with deeded 30’  floating dock access  '
      },
      {
        content: '• 170’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '33': {
    id: 33,
    estateName: 'Ocean Pavilion 15',
    sold: false,
    estateImage: 'Pavillion_15_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion13_14_15_2A9A0661.jpg',
    lotSouthImage: '',
    mapImage: 'Ocean_Pavilion_Lot_15.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Ocean_Pavilion_15.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 900 sf under A/C, 1,600 sf overall external'
      },
      {
        content: '• Lot Size 5,571sf + 600 sf deeded basin (6,171 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanfront to Harbour'
      },
      {
        content: '• 5,571  sf upland'
      },
      {
        content: '• 600 sf deeded basin'
      },
      {
        content: '• 30’ Beach Frontage'}, {content:'• 30’ Harbour Frontage'
      },
      {
        content: '•  Oceanfront to Harbour with deeded 30’  floating dock access  '
      },
      {
        content: '• 175’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '34': {
    id: 34,
    estateName: 'Ocean Pavilion 16',
    sold: false,
    estateImage: 'Pavillion_16_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion10_11_16_17_2A9A0655.jpg',
    lotSouthImage: '',
    mapImage: 'Ocean_Pavilion_Lot_16.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Ocean_Pavilion_16.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 900 sf under A/C, 1,600 sf overall external'
      },
      {
        content: '• Lot Size 5,549sf + 600 sf deeded basin (6,149 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanfront to Harbour'
      },
      {
        content: '• 5,549  sf upland'
      },
      {
        content: '• 600 sf deeded basin'
      },
      {
        content: '• 30’ Beach Frontage'}, {content:'• 30’ Harbour Frontage'
      },
      {
        content: '•  Oceanfront to Harbour with deeded 30’  floating dock access  '
      },
      {
        content: '• 178’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '35': {
    id: 35,
    estateName: 'Ocean Pavilion 17',
    sold: false,
    estateImage: 'Pavillion_17_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion10_11_16_17_2A9A0655.jpg',
    lotSouthImage: '',
    mapImage: 'Ocean_Pavilion_Lot_17.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Ocean_Pavilion_17.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 900 sf under A/C, 1,600 sf overall external'
      },
      {
        content: '• Lot Size 5,402sf + 600 sf deeded basin (6,002 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanfront to Harbour'
      },
      {
        content: '• 5,402  sf upland'
      },
      {
        content: '• 600 sf deeded basin'
      },
      {
        content: '• 30’ Beach Frontage'}, {content:'• 30’ Harbour Frontage'
      },
      {
        content: '•  Oceanfront to Harbour with deeded 30’  floating dock access  '
      },
      {
        content: '• 178’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '36': {
    id: 36,
    estateName: 'Ocean Pavilion 18',
    sold: false,
    estateImage: 'Pavillion_18_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion12_18_19_2A9A0656.jpg',
    lotSouthImage: '',
    mapImage: 'Ocean_Pavilion_Lot_18.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Ocean_Pavilion_18.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 900 sf under A/C, 1,600 sf overall external'
      },
      {
        content: '• Lot Size 5,126sf + 600 sf deeded basin (5,726 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanfront to Harbour'
      },
      {
        content: '• 5,126  sf upland'
      },
      {
        content: '• 600 sf deeded basin'
      },
      {
        content: '• 30’ Beach Frontage'}, {content:'• 30’ Harbour Frontage'
      },
      {
        content: '•  Oceanfront to Harbour with deeded 30’  floating dock access  '
      },
      {
        content: '• 177’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '37': {
    id: 37,
    estateName: 'Ocean Pavilion 19',
    sold: false,
    estateImage: 'Pavillion_19_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion12_18_19_2A9A0656.jpg',
    lotSouthImage: '',
    mapImage: 'Ocean_Pavilion_Lot_19.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Ocean_Pavilion_19.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 900 sf under A/C, 1,600 sf overall external'
      },
      {
        content: '• Lot Size 5,586sf + 600 sf deeded basin (6,186 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '•  Oceanfront to Harbour with deeded 30’  floating dock access  '
      },
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanfront to Harbour'
      },
      {
        content: '• 5,586  sf upland'
      },
      {
        content: '• 600 sf deeded basin'
      },
      {
        content: '• 30’ Beach Frontage'}, {content:'• 30’ Harbour Frontage'
      },
      {
        content: '• 172’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '38': {
    id: 38,
    estateName: 'Ocean Pavilion 20',
    sold: false,
    estateImage: 'Pavillion_20_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion20_2A9A0664.jpg',
    lotSouthImage: '',
    mapImage: 'Ocean_Pavilion_Lot_20.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Ocean_Pavilion_20.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 900 sf under A/C, 1,600 sf overall external'
      },
      {
        content: '• Lot Size 5,586sf + 600 sf deeded basin (6,186 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanfront to Harbour'
      },
      {
        content: '• 5,586  sf upland'
      },
      {
        content: '• 600 sf deeded basin'
      },
      {
        content: '• 30’ Beach Frontage'}, {content:'• 30’ Harbour Frontage'
      },
      {
        content: '•  Oceanfront to Harbour with deeded 30’  floating dock access  '
      },
      {
        content: '• 165’ Lot Depth'
      },
      {
        content: '• 14’ Lot Elevation'
      }
    ]
  },
  '39': {
    id: 39,
    estateName: 'Harbour Pavilion 21',
    sold: false,
    estateImage: 'Pavillion_21_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion21_2A9A0603.jpg',
    lotSouthImage: '',
    mapImage: 'Harbour_Pavilion_Lot_21.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Harbour_Pavilions_21.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 4,844sf + 1,400 sf deeded basin (6,244 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanview to Harbour'
      },
      {
        content: '• 4,844  sf upland'
      },
      {
        content: '• 1,400 sf deeded basin'
      },
      {
        content: '• 70’ Harbour Frontage'
      },
      {
        content: '•  Oceanview on the Harbour With Private 40’ Boat Slip '
      },
      {
        content: '• 140’ Lot Depth'
      },
      {
        content: '• 20’ Lot Elevation'
      }
    ]
  },
  '40': {
    id: 40,
    estateName: 'Harbour Pavilion 22',
    sold: false,
    estateImage: 'Pavillion_22_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion22_23_2A9A0595.jpg',
    lotSouthImage: '',
    mapImage: 'Harbour_Pavilion_Lot_22.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Harbour_Pavilions_22.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 4,622sf + 1,400 sf deeded basin (6,022 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanview to Harbour'
      },
      {
        content: '• 4,622  sf upland'
      },
      {
        content: '• 1,400.00 sf deeded basin'
      },
      {
        content: '• 70’ Harbour Frontage'
      },
      {
        content: '•  Oceanview on the Harbour With Private 40’ Boat Slip '
      },
      {
        content: '• 119’ Lot Depth'
      },
      {
        content: '• 20’ Lot Elevation'
      }
    ]
  },
  '41': {
    id: 41,
    estateName: 'Harbour Pavilion 23',
    sold: false,
    estateImage: 'Pavillion_23_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion22_23_2A9A0595.jpg',
    lotSouthImage: '',
    mapImage: 'Harbour_Pavilion_Lot_23.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Harbour_Pavilions_23.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 4,488sf + 1,200 sf deeded basin (5,688 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanview to Harbour'
      },
      {
        content: '• 4,488  sf upland'
      },
      {
        content: '• 1,200.00 sf deeded basin'
      },
      {
        content: '• 60’ Harbour Frontage'
      },
      {
        content: '•  Oceanview on the Harbour With Private 40’ Boat Slip '
      },
      {
        content: '• 120’ Lot Depth'
      },
      {
        content: '• 20’ Lot Elevation'
      }
    ]
  },
  '42': {
    id: 42,
    estateName: 'Harbour Pavilion 24',
    sold: false,
    estateImage: 'Pavillion_24_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion24_30_2A9A0615.jpg',
    lotSouthImage: '',
    mapImage: 'Harbour_Pavilion_Lot_24.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Harbour_Pavilions_24.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 4,604sf + 760 sf deeded basin (5,364 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanview to Harbour'
      },
      {
        content: '• 4,604  sf upland'
      },
      {
        content: '• 760.00 sf deeded basin'
      },
      {
        content: '• 38 Harbour Frontage'
      },
      {
        content: '•  Oceanview on the Harbour With Private 30’ Boat Slip '
      },
      {
        content: '• 120’ Lot Depth'
      },
      {
        content: '• 17’ Lot Elevation'
      }
    ]
  },
  '43': {
    id: 43,
    estateName: 'Harbour Pavilion 25',
    sold: false,
    estateImage: 'Pavillion_25_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion25_26_2A9A0621.jpg',
    lotSouthImage: '',
    mapImage: 'Harbour_Pavilion_Lot_25.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Harbour_Pavilions_25.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 4,582sf + 700 sf deeded basin (5,282 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanview to Harbour'
      },
      {
        content: '• 4,582  sf upland'
      },
      {
        content: '• 700.00 sf deeded basin'
      },
      {
        content: '• 35 Harbour Frontage'
      },
      {
        content: '•  Oceanview on the Harbour With Private 30’ Boat Slip '
      },
      {
        content: '• 110’ Lot Depth'
      },
      {
        content: '• 17’ Lot Elevation'
      }
    ]
  },
  '44': {
    id: 44,
    estateName: 'Harbour Pavilion 26',
    sold: false,
    estateImage: 'Pavillion_26_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion25_26_2A9A0621.jpg',
    lotSouthImage: '',
    mapImage: 'Harbour_Pavilion_Lot_26.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Harbour_Pavilions_26.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 4,534sf + 650 sf deeded basin (5,184 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanview to Harbour'
      },
      {
        content: '• 4,534  sf upland'
      },
      {
        content: '• 650.00 sf deeded basin'
      },
      {
        content: '• 30 Harbour Frontage'
      },
      {
        content: '•  Oceanview on the Harbour With Private 30’ Boat Slip '
      },
      {
        content: '• 105’ Lot Depth'
      },
      {
        content: '• 17’ Lot Elevation'
      }
    ]
  },
  '45': {
    id: 45,
    estateName: 'Harbour Pavilion 27',
    sold: false,
    estateImage: 'Pavillion_27_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion27_28_29_2A9A0617.jpg',
    lotSouthImage: '',
    mapImage: 'Harbour_Pavilion_Lot_27.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Harbour_Pavilions_27.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 4,887sf + 660 sf deeded basin (5,547 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanview to Harbour'
      },
      {
        content: '• 4,887  sf upland'
      },
      {
        content: '• 660.00 sf deeded basin'
      },
      {
        content: '• 33’ Harbour Frontage'
      },
      {
        content: '•  Oceanview on the Harbour With Private 30’ Boat Slip '
      },
      {
        content: '• 105’ Lot Depth'
      },
      {
        content: '• 15’ Lot Elevation'
      }
    ]
  },
  '46': {
    id: 46,
    estateName: 'Harbour Pavilion 28',
    sold: false,
    estateImage: 'Pavillion_28_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion27_28_29_2A9A0617.jpg',
    lotSouthImage: '',
    mapImage: 'Harbour_Pavilion_Lot_28.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Harbour_Pavilions_28.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 4,423sf + 640 sf deeded basin (5,063 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanview to Harbour'
      },
      {
        content: '• 4,423  sf upland'
      },
      {
        content: '• 640.00 sf deeded basin'
      },
      {
        content: '• 32 Harbour Frontage'
      },
      {
        content: '•  Oceanview on the Harbour With Private 30’ Boat Slip '
      },
      {
        content: '• 105’ Lot Depth'
      },
      {
        content: '• 15’ Lot Elevation'
      }
    ]
  },
  '47': {
    id: 47,
    estateName: 'Harbour Pavilion 29',
    sold: false,
    estateImage: 'Pavillion_29_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion27_28_29_2A9A0617.jpg',
    lotSouthImage: '',
    mapImage: 'Harbour_Pavilion_Lot_29.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Harbour_Pavilions_29.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 3,967sf + 800 sf deeded basin (4,767 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanview to Harbour'
      },
      {
        content: '• 3,967  sf upland'
      },
      {
        content: '• 800.00 sf deeded basin'
      },
      {
        content: '• 40’ Harbour Frontage'
      },
      {
        content: '•  Oceanview on the Harbour With Private 40’ Boat Slip '
      },
      {
        content: '• 100’ Lot Depth'
      },
      {
        content: '• 15’ Lot Elevation'
      }
    ]
  },
  '48': {
    id: 48,
    estateName: 'Harbour Pavilion 30',
    sold: false,
    estateImage: 'Pavillion_30_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion24_30_2A9A0615.jpg',
    lotSouthImage: '',
    mapImage: 'Harbour_Pavilion_Lot_30.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Harbour_Pavilions_30.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 3,923 sf + 800 sf deeded basin (4,723 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanview to Harbour'
      },
      {
        content: '• 3,923  sf upland'
      },
      {
        content: '• 800.00 sf deeded basin'
      },
      {
        content: '• 40’ Harbour Frontage'
      },
      {
        content: '•  Oceanview on the Harbour With Private 40’ Boat Slip '
      },
      {
        content: '• 100’ Lot Depth'
      },
      {
        content: '• 15’ Lot Elevation'
      }
    ]
  },
  '49': {
    id: 49,
    estateName: 'Harbour Pavilion 31',
    sold: false,
    estateImage: 'Pavillion_31_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion31_32_2A9A0632.jpg',
    lotSouthImage: '',
    mapImage: 'Harbour_Pavilion_Lot_31.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Harbour_Pavilions_31.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 3,945sf + 800 sf deeded basin (4,745 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanview to Harbour'
      },
      {
        content: '• 3,945  sf upland'
      },
      {
        content: '• 800.00 sf deeded basin'
      },
      {
        content: '• 40’ Harbour Frontage'
      },
      {
        content: '•  Oceanview on the Harbour With Private 40’ Boat Slip '
      },
      {
        content: '• 100’ Lot Depth'
      },
      {
        content: '• 15’ Lot Elevation'
      }
    ]
  },
  '50': {
    id: 50,
    estateName: 'Harbour Pavilion 32',
    sold: false,
    estateImage: 'Pavillion_32_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion31_32_2A9A0632.jpg',
    lotSouthImage: '',
    mapImage: 'Harbour_Pavilion_Lot_32.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Harbour_Pavilions_32.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 3,945sf + 800 sf deeded basin (4,745 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanview to Harbour'
      },
      {
        content: '• 3,945  sf upland'
      },
      {
        content: '• 800.00 sf deeded basin'
      },
      {
        content: '• 40’ Harbour Frontage'
      },
      {
        content: '•  Oceanview on the Harbour With Private 40’ Boat Slip '
      },
      {
        content: '• 100’ Lot Depth'
      },
      {
        content: '• 15’ Lot Elevation'
      }
    ]
  },
  '51': {
    id: 51,
    estateName: 'Harbour Pavilion 33',
    sold: false,
    estateImage: 'Pavillion_33_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion33_34_35_2A9A0620.jpg',
    lotSouthImage: '',
    mapImage: 'Harbour_Pavilion_Lot_33.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Harbour_Pavilions_33.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 3,945sf + 800 sf deeded basin (4,745 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanview to Harbour'
      },
      {
        content: '• 3,945  sf upland'
      },
      {
        content: '• 800.00 sf deeded basin'
      },
      {
        content: '• 40’ Harbour Frontage'
      },
      {
        content: '•  Oceanview on the Harbour With Private 40’ Boat Slip '
      },
      {
        content: '• 100’ Lot Depth'
      },
      {
        content: '• 15’ Lot Elevation'
      }
    ]
  },
  '52': {
    id: 52,
    estateName: 'Harbour Pavilion 34',
    sold: false,
    estateImage: 'Pavillion_34_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion33_34_35_2A9A0620.jpg',
    lotSouthImage: '',
    mapImage: 'Harbour_Pavilion_Lot_34.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Harbour_Pavilions_34.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 3,910sf + 800 sf deeded basin (4,710 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanview to Harbour'
      },
      {
        content: '• 3,910  sf upland'
      },
      {
        content: '• 800.00 sf deeded basin'
      },
      {
        content: '• 40’ Harbour Frontage'
      },
      {
        content: '•  Oceanview on the Harbour With Private 40’ Boat Slip '
      },
      {
        content: '• 100’ Lot Depth'
      },
      {
        content: '• 15’ Lot Elevation'
      }
    ]
  },
  '53': {
    id: 53,
    estateName: 'Harbour Pavilion 35',
    sold: false,
    estateImage: 'Pavillion_35_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion33_34_35_2A9A0620.jpg',
    lotSouthImage: '',
    mapImage: 'Harbour_Pavilion_Lot_35.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Harbour_Pavilions_35.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 3,563sf + 850 sf deeded basin (4,363 sf overall)'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,595,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Oceanview to Harbour'
      },
      {
        content: '• 3,563  sf upland'
      },
      {
        content: '• 850.00 sf deeded basin'
      },
      {
        content: '• 40’ Harbour Frontage'
      },
      {
        content: '•  Oceanview on the Harbour With Private 40’ Boat Slip '
      },
      {
        content: '• 95’ Lot Depth'
      },
      {
        content: '• 15’ Lot Elevation'
      }
    ]
  },
  '54': {
    id: 54,
    estateName: 'Hilltop Pavilion 36',
    sold: false,
    estateImage: 'Pavillion_36_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion36_37_38_2A9A0461.jpg',
    lotSouthImage: '',
    mapImage: 'Hilltop_Pavilion_Lot_36.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Hilltop_Pavilion_36.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 7,331sf'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,500,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Hilltop Pavilion'
      },
      {
        content: '• 7,331  sf upland'
      },
      {
        content: '• Community Deeded basin'
      },
      {
        content: '• 25’ Harbour Frontage'
      },
      {
        content: '• 1 of 7 30’ slots in Common Deeded Floating Dock '
      },
      {
        content: '• 155’ Lot Depth'
      },
      {
        content: '• 35’ Lot Elevation'
      }
    ]
  },
  '55': {
    id: 55,
    estateName: 'Hilltop Pavilion 37',
    sold: false,
    estateImage: 'Pavillion_37_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion36_37_38_2A9A0461.jpg',
    lotSouthImage: '',
    mapImage: 'Hilltop_Pavilion_Lot_37.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Hilltop_Pavilion_37.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 5,139sf'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,500,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Hilltop Pavilion'
      },
      {
        content: '• 5,139  sf upland'
      },
      {
        content: '• Community Deeded basin'
      },
      {
        content: '• 25’ Harbour Frontage'
      },
      {
        content: '• 1 of 7 30’ slots in Common Deeded Floating Dock '
      },
      {
        content: '• 125’ Lot Depth'
      },
      {
        content: '• 35’ Lot Elevation'
      }
    ]
  },
  '56': {
    id: 56,
    estateName: 'Hilltop Pavilion 38',
    sold: false,
    estateImage: 'Pavillion_38_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion36_37_38_2A9A0461.jpg',
    lotSouthImage: '',
    mapImage: 'Hilltop_Pavilion_Lot_38.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Hilltop_Pavilion_38.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 6,022sf'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,500,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Hilltop Pavilion'
      },
      {
        content: '• 6,022  sf upland'
      },
      {
        content: '• Community Deeded basin'
      },
      {
        content: '• 25’ Harbour Frontage'
      },
      {
        content: '• 1 of 7 30’ slots in Common Deeded Floating Dock '
      },
      {
        content: '• 125’ Lot Depth'
      },
      {
        content: '• 35’ Lot Elevation'
      }
    ]
  },
  '57': {
    id: 57,
    estateName: 'Hilltop Pavilion 39',
    sold: false,
    estateImage: 'Pavillion_39_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion39_2A9A0463.jpg',
    lotSouthImage: '',
    mapImage: 'Hilltop_Pavilion_Lot_39.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Hilltop_Pavilion_39.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 6,213sf'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,500,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Hilltop Pavilion'
      },
      {
        content: '• 6,213  sf upland'
      },
      {
        content: '• Community Deeded basin'
      },
      {
        content: '• 25’ Harbour Frontage'
      },
      {
        content: '• 1 of 7 30’ slots in Common Deeded Floating Dock '
      },
      {
        content: '• 125’ Lot Depth'
      },
      {
        content: '• 35’ Lot Elevation'
      }
    ]
  },
  '58': {
    id: 58,
    estateName: 'Hilltop Pavilion 40',
    sold: false,
    estateImage: 'Pavillion_40_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion40_41_2A9A0460.jpg',
    lotSouthImage: '',
    mapImage: 'Hilltop_Pavilion_Lot_40.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Hilltop_Pavilion_40.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 5,828sf'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,500,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Hilltop Pavilion'
      },
      {
        content: '• 5,828  sf upland'
      },
      {
        content: '• Community Deeded basin'
      },
      {
        content: '• 42’ Harbour Frontage'
      },
      {
        content: '• 1 of 7 30’ slots in Common Deeded Floating Dock '
      },
      {
        content: '• 120’ Lot Depth'
      },
      {
        content: '• 35’ Lot Elevation'
      }
    ]
  },
  '59': {
    id: 59,
    estateName: 'Hilltop Pavilion 41',
    sold: false,
    estateImage: 'Pavillion_41_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion40_41_2A9A0460.jpg',
    lotSouthImage: '',
    mapImage: 'Hilltop_Pavilion_Lot_41.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Hilltop_Pavilion_41.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 4,453sf'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,500,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Hilltop Pavilion'
      },
      {
        content: '• 4,453  sf upland'
      },
      {
        content: '• Community Deeded basin'
      },
      {
        content: '• 42’ Harbour Frontage'
      },
      {
        content: '• 1 of 7 30’ slots in Common Deeded Floating Dock '
      },
      {
        content: '• 135’ Lot Depth'
      },
      {
        content: '• 35’ Lot Elevation'
      }
    ]
  },
  '60': {
    id: 60,
    estateName: 'Hilltop Pavilion 42',
    sold: false,
    estateImage: 'Pavillion_42_WL_NFT.jpg',
    price: '$10,000',
    lotEastImage: 'Pavilion42_2A9A0456.jpg',
    lotSouthImage: '',
    mapImage: 'Hilltop_Pavilion_Lot_42.jpg',
    floorPlan: {
      link: 'pavilion_plan.pdf',
      image: 'pavilion_plan-1.jpg'
    },
    lotPlan: {
      image: 'Lot_Aerial_Hilltop_Pavilion_42.jpg'
    },
    estateShowcaseImgData: [
      {
        id: '0',
        label: 'Lorem ipsum dolar sit amet',
        image: 'showcaseImg2.jpg'
      }
    ],
    keyDetails: [
      {
        content: '• 1 Bedroom / 1 Bath'
      },
      {
        content: '• 1,000 sf under A/C, 1,700 sf overall external'
      },
      {
        content: '• Lot Size 6,696sf'
      },
      {
        content: '• Right to Ownership NFT starting at: $1,500,000.00',
        style: 'italics'
      },
      {
        content:
          'With this Allowlist NFT, you secure your right to mint the Right to Ownership NFT during the Allowlist period.'
      }
    ],
    furtherDetails: [
      {
        content: '• Construction Ready Site '
      },
      {
        content: '• Hilltop Pavilion'
      },
      {
        content: '• 6,696  sf upland'
      },
      {
        content: '• Community Deeded basin'
      },
      {
        content: '• 40’ Harbour Frontage'
      },
      {
        content: '• 1 of 7 30’ slots in Common Deeded Floating Dock '
      },
      {
        content: '• 170’ Lot Depth'
      },
      {
        content: '• 35’ Lot Elevation'
      }
    ]
  }
}

export default EstateData
export type { estateData, allEstateData }
