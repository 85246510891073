import React from 'react'
import NFTUtilDiagram from '../NFTUtilDiagram'
import PrimaryButton from '../PrimaryButton'
import { useNavigate } from 'react-router-dom'
import Overlay from '../Overlay'

const NFTUtilSection: React.FC = () => {
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate('/gallery')
  }
  return (
    <div className='flex flex-col'>
      <section className='flex flex-col md:items-center md:justify-center bg-agia-primary pb-4 md:pb-12 px-4 pt-8'>
        <h1 className='text-center font-Agatho text-white text-2xl md:text-3xl lg:text-4xl 2xl:text-6xl 2xl:leading-normal tracking-normal lg:tracking-wide'>
          AN NFT WITH FIRST-OF-ITS-KIND UTILITY.
        </h1>
        <p className='font-AjensonPro font-normal text-lg text-center lg:text-xl xl:text-2xl text-h2-section-secondary mt-4  md:w-[85%] lg:w-[70%] max-w-[70rem]'>
          The first step toward membership is reserving your homesite by minting an Allowlist NFT.
          This secures your right to mint the Right to Ownership NFT during the exclusive Allowlist
          period.
        </p>
        <NFTUtilDiagram />
        <PrimaryButton onClick={handleNavigate} message='VIEW COLLECTION' wide />
      </section>
      <section className='bg-[#fefefe] flex flex-col items-center justify-center py-4 md:py-8'>
        <div className='flex flex-col items-center justify-center '>
          <figure
            className={`reflection-rounded bg-agiaKey w-[15rem] h-[9rem] rounded-xl my-4 lg:mb-12 md:w-[18rem] md:h-[12rem] lg:w-[24rem] lg:h-[15rem] xl:w-[28rem] xl:h-[18rem]`}
          ></figure>
        </div>
        <Overlay color={'from-[rgba(242,236,229,0.5)]'} options={'w-full px-4'}>
          <div className='flex flex-col py-10 items-center justify-center md:justify-between md:ml-4 xl:ml-8'>
            <h2 id="amenities" className='font-AjensonPro text-h1-section-primary text-lg md:text-xl lg:text-3xl xl:text-4xl text-center'>
              The final NFT, your AGIA Key, unlocks the access that comes with ownership.
            </h2>
            <div className='block md:flex md:flex-row items-stretch	 md:justify-center font-AjensonPro font-normal text-agia-primary my-4 md:py-8 px-4 text-sm lg:text-lg xl:text-xl xs:mb-0'>
              <ul className='md:basis-1/2 pl-4 list-disc list-outside max-w-[32rem] mx-6 my-2'>
                <li className='mb-2 md:mb-3'>AGIA vertical family membership</li>
                <li className='mb-2 md:mb-3'>
                  AGIA Key allows priority access to the Dry Storage Mint (planned in Phase 2)
                </li>
                <li className='mb-2 md:mb-3'>
                  100 rounds of golf at neighboring Greg Norman-designed course, consistently
                  regarded as one of the best in the Caribbean by Travel + Leisure Golf
                </li>
                <li className='mb-2 md:mb-3'>
                  Courtesy transportation to/from airport and FBO and around island
                </li>
                <li className='mb-2 md:mb-3'>Access to AGIA concierge service (available at completion of phase 1)</li>
              </ul>
              <ul className='md:basis-1/2 pl-4 list-disc list-outside max-w-[32rem] mx-6 my-2'>
                <li className='mb-2'>Included expedited residency fee (a $20k value)</li>
                <li className='mb-2'>
                  One custom AGIA 4-seater golf cart for Pavilions; two carts for Villa homesites (furnished at completion of construction of individual pavilions and villas)
                </li>
                <li className='mb-2'>Access to AGIA Beach (phase 1)</li>
                <li className='mb-2'>Access to Yacht Club (planned in phase 2)</li>
                <li className='mb-2'>Access to AGIA Spa and Wellness Center</li>
                <li className='mb-2'>Access to the AGIA Fitness Center</li>
                <li className='mb-2'>Access to Outdoor Pursuits</li>
              </ul>
            </div>
          </div>
        </Overlay>
      </section>
    </div>
  )
}
export default NFTUtilSection
