import React, { MouseEvent } from 'react'
import FAQCategoryButton from './FAQCategoryButton'
import { useDispatch, useSelector } from 'react-redux'
import { setCategory, setData, FAQCategoryState } from '../feature/FAQCategorySlice'
import { FAQDataBasic, FAQDataLegal, FAQDataTaxes, FAQDataResidency } from '../utils/FAQdata'

const FAQCategory: React.FC = () => {
  const dispatch = useDispatch()
  const { category } = useSelector((state: { faqCategory: FAQCategoryState }) => state.faqCategory)
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement
    dispatch(setCategory(target.innerText))
    switch (target.innerText) {
      case 'Basic':
        dispatch(setData(FAQDataBasic))
        break
      case 'Legal and Ownership':
        dispatch(setData(FAQDataLegal))
        break
      case 'Taxes and Fees':
        dispatch(setData(FAQDataTaxes))
        break
      case 'Residency, and The Bahamas':
        dispatch(setData(FAQDataResidency))
        break
    }
  }
  const defaultStyle =
    'border border-countdown-text font-AJensonProSemiBold text-countdown-text p-2 my-2 hover:border-title-blue hover:text-title-blue lg:p-4 lg:text-base xl:text-lg 3xl:w-[60%]'
  const selectedStyle =
    'border border-title-blue font-AJensonProSemiBold text-title-blue p-2 my-2 lg:p-4 lg:text-base xl:text-lg 3xl:w-[60%]'

  return (
    <div className='flex flex-col w-full 3xl:items-center'>
      <FAQCategoryButton
        style={category === 'Basic' ? selectedStyle : defaultStyle}
        onClick={handleClick}
        message='Basic'
      />
      <FAQCategoryButton
        style={category === 'Legal and Ownership' ? selectedStyle : defaultStyle}
        onClick={handleClick}
        message='Legal and Ownership'
      />
      <FAQCategoryButton
        style={category === 'Taxes and Fees' ? selectedStyle : defaultStyle}
        onClick={handleClick}
        message='Taxes and Fees'
      />
      <FAQCategoryButton
        style={category === 'Residency, and The Bahamas' ? selectedStyle : defaultStyle}
        onClick={handleClick}
        message='Residency, and The Bahamas'
      />
    </div>
  )
}
export default FAQCategory
