import { createSlice } from '@reduxjs/toolkit'

export type ModalState = {
  showModal: boolean
  modalImg: string
}

const initialState: ModalState = {
  showModal: false,
  modalImg: ''
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.showModal = action.payload
    },
    setModalImg: (state, action) => {
      state.modalImg = action.payload
    }
  }
})
export const { setModal, setModalImg } = modalSlice.actions
export default modalSlice.reducer
