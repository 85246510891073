import React from 'react'
import MiddleInfoImage from '../assets/img/2121_Rocker Point_22-0330 Rendering_6.jpg'
import { LandingParagraphText } from './AgiaTextFonts'

function MiddleInfoTop() {
  return (
    <div className='flex flex-col px-4 lg:px-24 2xl:px-36 pt-2 md:pt-8 text-center'>
      <header className='text-center font-Agatho text-agia-blue text-2xl md:text-4xl lg:text-5xl 2xl:text-6xl 2xl:leading-normal tracking-normal mb-2 md:mb-4'>
        A HAVEN IN EVERY SENSE OF THE WORD.
      </header>

      <h2 className='md:hidden mb-2 md:mb-6 font-AJensonProSemiBold text-xl lg:text-2xl 2xl:text-4xl text-agia-grey'>
        A crypto-native luxury community on Great Exuma, Bahamas.
      </h2>

      <LandingParagraphText
        text='Owning at AGIA gives you an expedited pathway to Bahamian permanent residency, along with
          immediate access to one of the world’s most sophisticated offshore banking systems. Over 250 licensed banks and trust companies are available to AGIA Keyholders. In the
          Bahamas, capital gains, corporate earnings, personal income, inheritance, dividends,
          resident corporations, partnerships, and trusts are all tax-free.'
        extraClassNames='w-full mb-6'
      />

      <img src={MiddleInfoImage} className='px-0 py-8 md:px-10 scale-x-[-1] sm:scale-x-[1]' />
    </div>
  )
}

export default MiddleInfoTop
