import React from 'react'

type Props = {
  message: string
  bgImg: string
}

const NFTDiagram: React.FC<Props> = ({ message, bgImg }) => {
  return (
    <div className='flex flex-col items-center justify-center my-4'>
      <figure
        className={`reflection ${bgImg} w-[15rem] h-[15rem] md:w-[12rem] md:h-[12rem] lg:w-[17rem] lg:h-[17rem] xl:w-[22rem] xl:h-[22rem]`}
      ></figure>
      <h3 className='font-Agatho text-xl font-normal md:text-sm lg:text-2xl text-countdown-primary z-10 mt-2 xl:mt-4'>
        {message}
      </h3>
    </div>
  )
}

export default NFTDiagram
