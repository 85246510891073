import React from 'react'

type Props = {
  top?: boolean | null
  options?: string
  children?: React.ReactNode | React.ReactNode[]
  color?: string
}

const Overlay: React.FC<Props> = ({
  children,
  top,
  options = '',
  color = 'from-[rgba(0,0,0,0.85)]'
}) => {
  return (
    <div
      className={
        top
          ? `bg-gradient-to-b ${color} to-transparent ${options}`
          : `bg-gradient-to-t ${color} to-transparent ${options}`
      }
    >
      {children}
    </div>
  )
}

export default Overlay
