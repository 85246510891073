import React, { useState, useEffect } from 'react'
import EstateKeyDetails from '../EstateKeyDetails'
import EstateData, { estateData } from '../../utils/EstateDataSample'
import EstateFurtherDetails from '../EstateFurtherDetails'
import { useNavigate } from 'react-router-dom'

// this is a mock function.
function getEstate(id: string): estateData {
  return EstateData[id]
}

function EstateInfoSection(props: { id: string }) {
  const navigate = useNavigate()
  const [mapImage, setMapImage] = useState<string | undefined>()
  const [estate, setEstate] = useState({
    estateImage: '',
    estateName: '',
    price: '',
    keyDetails: [{ content: '' }],
    furtherDetails: [{ content: '' }]
  })

  useEffect(() => {
    const resultEstate = getEstate(props.id)
    if (!resultEstate) {
      navigate('/gallery')
    }

    if (resultEstate.mapImage) {
      setMapImage(resultEstate.mapImage)
    }
    setEstate(resultEstate)
  })

  const { estateName, price, keyDetails, furtherDetails, estateImage } = estate

  const estateProps = {
    estateImage,
    estateName,
    price,
    keyDetails,
    furtherDetails
  }

  return (
    <div>
      <EstateKeyDetails {...estate} />
      <EstateFurtherDetails {...estate} mapImage={mapImage} />
    </div>
  )
}

export default EstateInfoSection
