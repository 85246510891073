import React from 'react'
import { ConstructionText, ConstructionTeamList } from '../ConstructionText'
import ConstructionImgContainer from '../ConstructionImgContainer'
import Overlay from '../Overlay'
import useWindowSize from '../../utils/useWindowSize'
import constructionHeroImg from '../../assets/images/constructionHeroImg.jpg'
import constructionImg1 from '../../assets/images/constructionImg1.jpg'
import constructionImg2 from '../../assets/images/constructionImg2.jpg'
import constructionImg3 from '../../assets/images/constructionImg3.jpg'
import constructionImg4 from '../../assets/images/constructionImg4.jpg'

const ConstructionSection: React.FC = () => {
  const { width } = useWindowSize()
  return (
    <section className='flex flex-col items-center justify-center h-full '>
      <ConstructionText
        heading={'CONSTRUCTION-READY TODAY.'}
        body={`With roads, power, and other infrastructure
fully installed, all homesites are construction
ready. Already complete are a stunning 10-acre
man-made inland basin, featuring 9,000 linear
feet of custom installed seawall. Ownership and
expedited residency start December 2022.`}
      />
      <figure className='w-full h-[60%] max-h-[30rem] xl:max-h-[35rem] 2xl:max-h-[40rem] 3xl:max-h-[35vw] overflow-hidden my-8 2xl:my-16 3xl:my-20'>
        <img
          src={constructionHeroImg}
          className='w-full 3xl:mx-auto h-[60%] max-h-[30rem] xl:max-h-[35rem] 2xl:max-h-[40rem] 3xl:max-h-[35vw] object-cover'
        />
      </figure>
      <div className='pt-4'>
        <ConstructionText
          heading={`A WEALTH OF REAL
EXPERIENCES.`}
          body={`AGIA Keyholders have unmatched access to
the community activities and facilities, from a
private oceanfront restaurant, tiki bar and fitness center, to the
neighboring Greg Norman-designed golf course.
The crown jewel is a spectacular superyacht
marina, complete with celebrated dining and
boutiques, plus available dry storage facility (all planned in phase 2).`}
        />
      </div>

      {width < 768 ? (
        <div className='px-4 flex flex-col h-full my-8 2xl:my-16 3xl:my-28'>
          <ConstructionImgContainer src={constructionImg1} alt={'Luxurious yachts'} />
          <ConstructionImgContainer src={constructionImg2} alt={'guests on vacation'} />
          <ConstructionImgContainer
            src={constructionImg3}
            alt={'Beautiful birds eyeview of the island'}
          />
        </div>
      ) : (
        <div className='overflow-hidden flex flex-col grid sm:grid-cols-1 md:grid-cols-2 md:grid-flow-row gap-2 lg:gap-4 xl:gap-4 w-full px-4 md:px-0 my-8 2xl:my-16 3xl:my-28'>
          <ConstructionImgContainer src={constructionImg1} alt={'Luxurious yachts'} />
          <ConstructionImgContainer src={constructionImg2} alt={'guests on vacation'} />
          <ConstructionImgContainer src={constructionImg4} alt={'child playing with stingray'} />
          <ConstructionImgContainer
            src={constructionImg3}
            alt={'Beautiful birds eyeview of the island'}
          />
        </div>
      )}
      <Overlay color={'from-[rgba(242,236,229,0.5)]'} options={'w-full'}>
        <div className='flex flex-col items-center justify-center md:justify-between w-full mb-8 2xl:mb-20 3xl:mb-28 3xl:-mt-12'>
          <ConstructionText heading={'THE AGIA TEAM'} body={''} />
          <div className='flex flex-col md:flex-row md:justify-between w-full my-4 md:w-[70%] lg:w-[70%] xl:w-[40%]'>
            <ConstructionTeamList
              team={[
                'AGIA International',
                'Unchained Partners',
                'Chad Oppenheim Architect',
                'OA3 Strategy and Marketing'
              ]}
            />
            <ConstructionTeamList
              team={['JFB General Contractor', 
                     'Setai Group', 
                     'Gemini Trust Company',
                     'Big Person']}
            />
          </div>
        </div>
      </Overlay>
    </section>
  )
}
export default ConstructionSection
