import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
interface FormProps {
  fields: string[]
}

function Form(props: FormProps) {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const navigate = useNavigate()

  const smtpjs = window.Email

  const send = () => {
    smtpjs
      .send({
        SecureToken: '19df730d-0918-4913-9bc7-2a920c35136b',
        To: 'alex@unchainedpartners.com',
        From: 'Youssef@launchup.io',
        Subject: 'AGIA Contact Form Submission',
        Body: `
          <br/> The following message was sent from the Agia contact form:
          <br/> First Name: ${firstName}
          <br/> Last Name: ${lastName}
          <br/> Email: ${email}
          <br/> Message: ${message}
          <br/> This message was sent at ${new Date().toLocaleString()}
          `
      })
      .then((message) => {
        console.log(message)
        setFirstName('')
        setLastName('')
        setEmail('')
        setMessage('')
      })
  }
  function handleSubmit(event: React.SyntheticEvent) {
    (window as any).gtag('event', 'sign_up', {});
    navigate('/thank-you')
  }

  useEffect(() => {
    const performTimeConsumingTask = async () => {
      return new Promise((resolve) =>
        setTimeout(() => {
          resolve('result')
        }, 1000)
      )
    }
    const loadButton = async () => {
      console.log('HERE 0')
      await performTimeConsumingTask()
      const el = document.getElementById('submit_button')
      console.log('HERE', el)
    }
    loadButton()
  }, [])

  function handleInputChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const target = event.target as HTMLTextAreaElement
    switch (target.name) {
      case 'First Name':
        setFirstName(target.value)
        break
      case 'Last Name':
        setLastName(target.value)
        break
      case 'Email':
        setEmail(target.value)
        break
      default:
        setMessage(target.value)
    }
  }

  function getValue(name: string) {
    switch (name) {
      case 'First Name':
        return firstName
      case 'Last Name':
        return lastName
      case 'Email':
        return email
      default:
        return message
    }
  }

  function renderInputTextArea(field: string) {
    if (field === 'Message') {
      return (
        <textarea
          name='textarea'
          className='w-full bg-agia text-black font-sans px-2 peer'
          required
          rows={6}
          value={message}
          onChange={handleInputChange}
        />
      )
    } else {
      return (
        <input
          className={`w-full bg-agia text-black font-sans px-2  peer peer${
            field == 'Message' ? 'h-32' : 'h-8 md:h-9'
          }`}
          type={field === 'Email' ? 'email' : 'text'}
          required
          name={field}
          value={getValue(field)}
          onChange={handleInputChange}
        />
      )
    }
  }

  return (
    <div className='flex justify-center pb-40 md:pb-80'>
      <form
        onSubmit={handleSubmit}
        className='border-white border-[1px] flex flex-col justify-center py-4 px-4 sm:px-6 w-[18rem] sm:w-[36rem] md:w-[45rem]'
        id='form'
      ></form>
    </div>
  )
}

export default Form
