import React from 'react'
import FAQContents from '../FAQContents'
import { LandingHeaderText } from '../AgiaTextFonts'
import FAQCategory from '../FAQCategory'
import { useSelector } from 'react-redux'
import { FAQCategoryState } from '../../feature/FAQCategorySlice'

const FAQSection: React.FC = () => {
  const { data } = useSelector((state: { faqCategory: FAQCategoryState }) => state.faqCategory)

  return (
    <div className='flex flex-col items-center px-4 sm:px-16 lg:px-24 2xl:px-36 py-4 lg:py-12'>
      <LandingHeaderText text='FAQ' extraClassNames='mb-2 md:mb-4' />

      <div className='flex flex-col md:flex-row w-full '>
        <FAQCategory />
        <div className='font-AjensonPro text-agia-grey text-lg md:mt-0 lg:text-2xl 2xl:text-3xl my-5 lg:my-10 lg:mt-0 mx-6 lg:mx-12 w-full'>
          <FAQContents data={data} />
        </div>
      </div>
    </div>
  )
}

export default FAQSection
