import React, { useState } from 'react'

type Props = {
  title?: string
  contents?: { __html: string }
}

function FAQAccordian(props: Props) {
  const [isActive, setIsActive] = useState(false)

  return (
    <div className='border-b-FAQ-grey border-2 border-x-0 border-t-0 py-2 lg:py-6'>
      <div className='cursor-pointer flex flex-row' onClick={() => setIsActive(!isActive)}>
        <div className='pr-2'>{isActive ? '-' : '+'}</div>
        <div className='font-AJensonProSemiBold'>{props.title}</div>
      </div>
      {isActive && (
        <div
          className='p-2 leading-snug sm:leading-normal'
          dangerouslySetInnerHTML={props.contents}
        />
      )}
    </div>
  )
}

export default FAQAccordian
