import React, {useEffect} from 'react'
import Footer from '../components/Footer'
import ThankYouSection from '../components/ThankYouPage/ThankYouSection'

const LandingPage: React.FC = () => {
  useEffect(() => {
    document.title = 'Agia - Thank You';
  }, []);
  
  return (
    <div>
      <ThankYouSection />

      <Footer />
    </div>
  )
}

export default LandingPage
