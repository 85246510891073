import React from 'react'

type Props = {
  src: string
  alt: string
}

const ConstructionImgContainer: React.FC<Props> = ({ src, alt }) => {
  return (
    <figure className='w-full h-[60vw] md:h-full md:max-h-full xl:h-[30vw] xl:max-h-[40rem] 2xl:max-h-full my-4 md:my-0 overflow-hidden'>
      <img
        className='w-full h-full xl:max-h-[40rem] 2xl:max-h-full overflow-hidden object-cover '
        src={src}
        alt={alt}
      />
    </figure>
  )
}
export default ConstructionImgContainer
