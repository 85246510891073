import { useEthers } from '@usedapp/core'
import React from 'react'
import Web3Modal from 'web3modal'

type Props = {
  color?: string
}

const DisconnectWallet: React.FC<Props> = ({ color = 'white' }) => {
  const { deactivate } = useEthers()
  const web3Modal = new Web3Modal({})
  const handleDisconnectWallet = () => {
    deactivate()
    web3Modal.clearCachedProvider()
  }
  return (
    <button
      onClick={handleDisconnectWallet}
      className={`curor-pointer border border-${color} font-MontserratMedium text-white text-xs px-4 p-1 rounded-md bg-black/30`}
    >
      <p className='pt-1 xl:text-xl lg:text-md relative -top-1 mt-1'>DISCONNECT WALLET</p>
    </button>
  )
}
export default DisconnectWallet
