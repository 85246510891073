import React from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  color?: string
}

const ReturnButton: React.FC<Props> = ({ color = 'white' }) => {
  const navigate = useNavigate()
  const handleConnectWallet = () => {
    navigate('/')
  }
  return (
    <button
      onClick={handleConnectWallet}
      className={`curor-pointer border border-${color} font-Agatho text-white text-xs px-4`}
    >
      <p className='p-1 xl:text-sm'>RETURN TO HOME</p>
    </button>
  )
}
export default ReturnButton
