import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setModal, ModalState } from '../feature/modalSlice'
import { MdClose } from 'react-icons/md'

const Modal: React.FC = () => {
  const dispatch = useDispatch()
  const { modalImg } = useSelector((state: { modal: ModalState }) => state.modal)
  return (
    <div
      className={`flex px-4 md:px-0 inset-0 fixed top-0 left-0 w-full h-full overflow-auto bg-black/70 justify-center items-center`}
    >
      <figure
        className='flex items-start justify-end bg-cover bg-center w-full h-[35%] md:w-[80%] md:h-[40%] lg:h-[50%] xl:h-[80%]'
        style={{ backgroundImage: `url(${modalImg})` }}
      >
        <div className='flex items-center justify-center text-xl md:text-2xl lg:text-3xl xl:text-4xl  bg-[rgba(0,0,0,0.3)]  w-[10vw] h-[10vw] md:w-[5vw] md:h-[5vw]'>
          <MdClose
            className='cursor-pointer text-[rgba(255,255,255,1)] '
            onClick={() => dispatch(setModal(false))}
          />
        </div>
      </figure>
    </div>
  )
}
export default Modal
