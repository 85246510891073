import { configureStore } from '@reduxjs/toolkit'
import modalReducer from '../feature/modalSlice'
import faqCategoryReducer from '../feature/FAQCategorySlice'

export const store = configureStore({
  reducer: {
    modal: modalReducer,
    faqCategory: faqCategoryReducer
  }
})
