import React, { MouseEvent, useEffect, useState } from 'react'
import ShowcaseCard from '../../components/EstatePage/ShowcaseCard'
import Overlay from '../Overlay'
import Modal from '../Modal'
import EstateData, { estateData } from '../../utils/EstateDataSample'
import { useDispatch, useSelector } from 'react-redux'
import { setModal, setModalImg, ModalState } from '../../feature/modalSlice'
import { LandingHeaderText } from '../AgiaTextFonts'
import useLockScroll from '../../utils/useLockScroll'
import { useNavigate } from 'react-router-dom'

function getEstate(id: string): estateData {
  return EstateData[id]
}

const ShowcaseSection: React.FC<{ id: string }> = (props: { id: string }) => {
  const navigate = useNavigate()
  const [estate, setEstate] = useState<estateData>({} as estateData)
  const [images, setImages] = useState([] as string[])
  const [lot1EastImage, setLot1EastImage] = useState()
  const [lot1SouthImage, setlot1SouthImage] = useState()
  const [lotImage, setLotImage] = useState()
  const [plan, setPlan] = useState()
  const [planImage, setPlanImage] = useState()

  const dispatch = useDispatch()
  useEffect(() => {
    const resultEstate = getEstate(props.id)
    if (!resultEstate) {
      navigate('/gallery')
    }
    setEstate(resultEstate)
  }, [])

  useEffect(() => {
    const fetchImage = async () => {
      try {
        for (let i = 0; i < estate.estateShowcaseImgData.length; i++) {
          const response = await import(
            `../../assets/estateAssets/${estate.estateShowcaseImgData[i].image}`
          )
          setImages((oldArray) => [...oldArray, response.default])
        }
        let response
        if (estate.lotEastImage) {
          response = await import(`../../assets/estateAssets/homesiteviews/${estate.lotEastImage}`)
          setLot1EastImage(response.default)
        }
        if (estate.lotSouthImage) {
          response = await import(`../../assets/estateAssets/homesiteviews/${estate.lotSouthImage}`)
          setlot1SouthImage(response.default)
        }
        if (estate.floorPlan) {
          response = await import(`../../assets/estateAssets/pdfs/${estate.floorPlan.image}`)
          setPlanImage(response.default)
          response = await import(`../../assets/estateAssets/pdfs/${estate.floorPlan.link}`)
          setPlan(response.default)
        }
        if (estate.lotPlan) {
          response = await import(`../../assets/estateAssets/estateviews/${estate.lotPlan.image}`)
          setLotImage(response.default)
        }
      } catch (err) {
        // console.log(err)
      }
    }

    fetchImage()
  }, [estate])

  const { showModal } = useSelector((state: { modal: ModalState }) => state.modal)
  useLockScroll()
  const onClick = (e: MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    switch (target.id) {
      case 'plansLot':
        dispatch(setModalImg(lotImage))
        dispatch(setModal(true))
        break
      case 'plansFloor':
        window.open(plan)
        break
      case 'lotSouth':
        dispatch(setModalImg(lot1SouthImage))
        dispatch(setModal(true))
        break
      case 'lotEast':
        dispatch(setModalImg(lot1EastImage))
        dispatch(setModal(true))
        break
      default:
        dispatch(setModalImg(images[Number(target.id)]))
        dispatch(setModal(true))
        break
    }
  }

  return (
    <section className={`my-4 `}>
      {showModal && <Modal />}
      <Overlay top color='from-[rgba(242,236,229,0.5)]'>
        <div className='flex flex-col w-full md:flex-row justify-centex px-4 lg:px-12 xl:px-24 md:gap-6 xl:gap-12'>
          <ShowcaseCard id={'plansLot'} label='Lot Plans' image={lotImage} onClick={onClick} />
          <ShowcaseCard id={'plansFloor'} label='Floor Plans' image={planImage} onClick={onClick} />
        </div>
      </Overlay>
      <div className='flex flex-col items-center w-full px-4 lg:px-12 xl:px-24 mb-8 md:mb-0'>
        <LandingHeaderText text='RENDERINGS' extraClassNames='lg:my-8' />
        <div className='w-full md:grid md:grid-flow-row md:grid-cols-2 justify-items-center items-center  md:gap-4 xl:gap-12'>
          {estate.estateShowcaseImgData && estate.estateShowcaseImgData.length === 3 ? (
            estate.estateShowcaseImgData
              .slice(0, 2)
              .map((card) => (
                <ShowcaseCard
                  key={card.id}
                  id={card.id}
                  label={card.label}
                  image={images[Number(card.id)]}
                  onClick={onClick}
                />
              ))
          ) : (
            <></>
          )}
        </div>
        <div className='w-full md:grid md:grid-flow-row justify-items-center items-center  md:gap-4 xl:gap-12'>
          {estate.estateShowcaseImgData ? (
            estate.estateShowcaseImgData
              .slice(-1)
              .map((card) => (
                <ShowcaseCard
                  key={card.id}
                  id={card.id}
                  label={card.label}
                  image={images[Number(card.id)]}
                  onClick={onClick}
                />
              ))
          ) : (
            <></>
          )}
        </div>
      </div>
      {estate.lotEastImage || estate.lotSouthImage ? (
        <div className='flex flex-col  items-center w-full px-4 lg:px-12 xl:px-24 md:mb-0'>
          <LandingHeaderText text='HOMESITE VIEWS' extraClassNames='lg:my-8' />
          <div className='w-full md:flex md:gap-4 xl:gap-12'>
            {lot1SouthImage ? (
              <ShowcaseCard
                label='Lot1, looking South'
                id={'lotSouth'}
                image={lot1SouthImage}
                onClick={onClick}
              />
            ) : null}
            {estate.lotEastImage ? (
              <ShowcaseCard
                id={'lotEast'}
                label='Lot1, looking east'
                image={lot1EastImage}
                onClick={onClick}
              />
            ) : null}
          </div>
        </div>
      ) : null}
    </section>
  )
}
export default ShowcaseSection
