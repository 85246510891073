import React from 'react'
import Header from '../Header'
import Overlay from '../Overlay'

const WelcomeSection: React.FC = () => {
  return (
    <div className='bg-hidden bg-welcomeSectionBg bg-no-repeat relative'>
      <section
        className={`flex flex-col justify-between bg-welcomeSectionBg bg-no-repeat bg-[length:853px_480px] md:bg-[length:1280px_720px] lg:bg-cover gap-28 md:h-[80vw] md:max-h-[720px] xl:max-h-[1080px] 2xl:max-h-[1080px] bg-top xl:h-[52vw]`}
      >
        <Overlay top color={'from-[rgba(0,0,0,0.7)]'} options={'h-[50%]'}>
          <Header logoColor='white' />
        </Overlay>

        <Overlay color='from-[rgba(0,0,0,1.0)]'>
          <div className='flex flex-col items-center text-white font-Agatho text-2xl lg:text-4xl xl:text-5xl 2xl:text-6xl md:tracking-widest mb-12 md:mb-20 lg:my-24 pt-20 xl:pt-0'>
            <h1 className='relative md:-bottom-10'>NEW-WORLD OWNERSHIP.</h1>
            <h1 className='relative md:-bottom-10'>REAL-WORLD PARADISE.</h1>
          </div>
        </Overlay>
      </section>
    </div>
  )
}
export default WelcomeSection
