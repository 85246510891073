import React, { MouseEvent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  id: string
  category: string
  price: string
  fullPrice: string
  houseSize: string
  bedroom: number
  bathroom: number
  lotSize: string
  src: string
  alt: string
  villa?: boolean
  sold?: boolean
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
}

const EstateCard: React.FC<Props> = ({
  id,
  sold,
  villa,
  category,
  price,
  fullPrice,
  houseSize,
  bedroom,
  bathroom,
  lotSize,
  src,
  alt
}) => {
  const navigate = useNavigate()
  const [image, setImage] = React.useState()

  useEffect(() => {
    const fetchImage = async () => {
      try {
        let response
        if (category.includes('Villa')) {
          response = await import(`../assets/estateAssets/WL_Villas/${src}`)
        } else {
          response = await import(`../assets/estateAssets/WL_Pavillions/${src}`)
        }
        setImage(response.default)
      } catch (err) {
        console.log(err)
      }
    }

    fetchImage()
  }, [src])

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    // const target = e.target as HTMLButtonElement
    navigate(`/estate/${id}`)
  }

  return (
    <button
      id={id}
      disabled={sold}
      onClick={onClick}
      className='disabled:cursor-not-allowed disabled:opacity-30 flex flex-col w-full h-full scale-[.90] lg:scale-100'
    >
      {villa ? (
        <div className='h-full bg-agia'>
          <figure className='border border-estate-card-border'>
            <img className='w-full h-full object-cover' src={image} alt={alt} />
          </figure>
          <div className='w-full text-left flex flex-col text-agia-primary p-2'>
            <div className='flex flex-col whitespace-nowrap font-Montserrat text-[0.6rem] sm:text-sm lg:text-sm xl:text-base 2xl:text-2xl text-center'>
              <p className='text-left font-semibold'>{`${category}`}&nbsp;</p>
              <p className='text-left font-bold my-1'>Starting at ${`${fullPrice}`}&nbsp;</p>
              <p className='text-left font-medium italic mb-1'>{`${price} Allowlist`}</p>
            </div>
            <div className='text-left text-[.5rem]  md:text-sm xl:text-base 2xl:text-2xl  font-Montserrat'>
              <p>{`4,500-6,500 sf | 4-6 bedrooms`}</p>
              <p>{`Lot Size: ${lotSize.split(' ')[3]} sf`}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className='h-full bg-agia'>
          <figure className='border border-estate-card-border'>
            <img className='w-full h-full object-cover' src={image} alt={alt} />
          </figure>
          <div className='w-full text-left flex flex-col text-agia-primary p-2'>
            <div className='flex flex-col whitespace-nowrap font-Montserrat text-[0.6rem] sm:text-sm lg:text-sm xl:text-base 2xl:text-2xl text-center'>
              <p className='text-left font-semibold'>{`${category}`}&nbsp;</p>
              <p className='text-left font-bold my-1'>Starting at ${`${fullPrice}`}&nbsp;</p>
              <p className='text-left font-medium italic mb-1'>{`${price} Allowlist`}</p>
            </div>
            <div className='text-left text-[.5rem]  md:text-sm xl:text-base 2xl:text-2xl  font-Montserrat'>
              <p>{`${houseSize} sf | ${bedroom} bedroom`}</p>
              <p>{`Lot Size: ${lotSize.split(' ')[3]} sf`}</p>
            </div>
          </div>
        </div>
      )}
      <div className='w-full bg-agia-primary py-2 font-AJensonProSemiBold lg:text-xl xl:text-2xl 2xl:text-3xl'>
        <h1 className='text-arrow-color'>DETAILS</h1>
      </div>
    </button>
  )
}
export default EstateCard
