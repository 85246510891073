import React from 'react'

type Props = {
  text: string
  extraClassNames?: string
  fontColor?: string
}
// extraClassNames props is for any additional styles that you would like to add to your text

const LandingHeaderText: React.FC<Props> = ({
  text,
  extraClassNames = '',
  fontColor = 'text-agia-blue'
}) => {
  return (
    <h2
      className={`text-center font-Agatho text-2xl md:text-3xl lg:text-5xl 2xl:text-6xl 2xl:leading-normal tracking-normal lg:tracking-wide ${extraClassNames} ${fontColor}`}
    >
      {text}
    </h2>
  )
}

const LandingParagraphText: React.FC<Props> = ({
  text,
  extraClassNames = '',
  fontColor = 'text-agia-grey'
}) => {
  return (
    <p
      className={`font-AjensonPro text-agia-grey text-md lg:text-xl 2xl:text-2xl md:leading-normal lg:leading-relaxed 2xl:leading-relaxed mb-2 ${extraClassNames} ${fontColor}`}
    >
      {text}
    </p>
  )
}

export { LandingHeaderText, LandingParagraphText }
