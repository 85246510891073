import React from 'react'

type Props = {
  title: string
  minPrice: string | number
  maxPrice: string | number
  description: string
  src: string
  alt: string
}

const ShowcaseCard: React.FC<Props> = ({
  title,
  minPrice = 'XX',
  maxPrice = 'XX',
  description,
  src,
  alt
}) => {
  return (
    <div className='w-full md:w-[45%]'>
      <figure className=''>
        <img className='w-full h-full object-cover' src={src} alt={alt} />
      </figure>
      <div className=''>
        <h1 className='font-AjensonPro font-light tracking-wide text-2xl lg:text-4xl text-title-blue mt-4 lg:mt-6'>
          {title}
        </h1>
        <h2 className='font-AJensonProSemiBoldIt text-lg lg:text-2xl text-agia-primary md:mt-1 xl:mt-2'>{`Allowlist NFT $${maxPrice}`}</h2>

        <p className='font-AjensonPro text-agia-primary lg:w-[75%] xl:w-[90%] text-lg md:text-xl lg:text-2xl leading-6 md:leading-relaxed my-4 md:mt-2 xl:my-4'>
          {description}
        </p>
      </div>
    </div>
  )
}
export default ShowcaseCard
