import React from 'react'
import Header from '../Header'

const IntroSection: React.FC = () => {
  return (
    <div className='bg-estateBackground bg-cover bg-center relative'>
      <section className='flex flex-col justify-between bg-gradient-to-t from-black/50 to-black/3 h-full'>
        <div>
          <Header logoColor='white' />
          <div className='flex items-center justify-center w-full text-white font-Agatho tracking-wide text-xl md:text-3xl xl:text-5xl my-4 md:my-12 text-center'>
            <div className='md:max-w-2xl lg:max-w-3xl xl:max-w-5xl px-2'>
              Mint an Allowlist NFT to Reserve Your Turnkey Pavilion or Villa Homesite
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default IntroSection
