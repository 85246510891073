import React, { useRef, useState, useEffect } from 'react'
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md'
import useWindowSize from '../utils/useWindowSize'
import { CarouselData, data } from '../utils/carouselImg'

const ImageCarousel: React.FC = () => {
  const maxScrollWidth = useRef<number>(0)
  const [currentIndex, setCurrentIndex] = useState(0)
  const carousel = useRef<HTMLDivElement>(null)
  const { width } = useWindowSize()

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1)
    }
  }

  const handleNext = () => {
    if (
      carousel.current !== null &&
      carousel.current.offsetWidth * currentIndex <= maxScrollWidth.current
    ) {
      setCurrentIndex((prevState) => prevState + 1)
    }
  }

  const isDisabled = (direction: string) => {
    if (direction === 'prev') {
      return currentIndex <= 0
    }

    if (direction === 'next' && carousel.current !== null) {
      return carousel.current.offsetWidth * currentIndex >= maxScrollWidth.current
    }

    return false
  }

  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      carousel.current.scrollLeft = carousel.current.offsetWidth * currentIndex
    }
  }, [currentIndex])

  useEffect(() => {
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0
  }, [width])

  return (
    <div className='carousel my-12 mx-auto'>
      <div className='relative overflow-hidden'>
        <div className='flex justify-between absolute top left w-full h-full'>
          <button
            onClick={handlePrevious}
            className='flex items-center justify-center hover:bg-black text-white w-12 md:w-24 h-full text-center hover:opacity-50 disabled:opacity-30 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300'
            disabled={isDisabled('prev')}
          >
            <MdArrowBackIos className='text-2xl md:text-6xl' />
            <span className='sr-only'>Prev</span>
          </button>
          <button
            onClick={handleNext}
            className='flex items-center justify-center hover:bg-black text-white w-12 md:w-24 h-full text-center hover:opacity-50 disabled:opacity-30 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300'
            disabled={isDisabled('next')}
          >
            <MdArrowForwardIos className='text-2xl md:text-6xl' />
            <span className='sr-only'>Next</span>
          </button>
        </div>
        <div
          ref={carousel}
          className='carousel-container relative w-full h-full flex gap-4 overflow-hidden scroll-smooth snap-x snap-mandatory touch-pan-x z-0'
        >
          {data.map((image: CarouselData, index: number) => {
            return (
              <div
                key={index}
                className={`carousel-item text-center relative w-40 ${image.width} h-40 md:h-64 lg:h-72 xl:h-[35rem] snap-start`}
              >
                <div
                  className={`h-full xl:h-[35rem] w-40 ${image.width} block bg-origin-padding bg-center bg-cover bg-no-repeat z-0`}
                  style={{ backgroundImage: `url(${image.imageUrl || ''})` }}
                >
                  <img
                    src={image.imageUrl || ''}
                    alt={image.title}
                    className='w-full aspect-square hidden'
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default ImageCarousel
