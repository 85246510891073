import React from 'react'

//  props that can be added are: default, xs, sm, md, lg, xl, and '2xl'
const defaultFontClass = {
  default: 'text-xs',
  md: 'md:text-base',
  lg: 'lg:text-xl',
  xl: 'text-2xl'
}

type Props = {
  onClick?: () => void
  message: string
  fontSize?: { [key: string]: string }
  wide?: boolean
}

const PrimaryButton: React.FC<Props> = ({
  message,
  onClick,
  fontSize = defaultFontClass,
  wide = false
}) => {
  const fontBuilder = () => {
    let classString = ''
    Object.keys(fontSize).forEach((item: string) => {
      classString += `${fontSize[item]} `
    })

    return classString
  }
  return (
    <button
      className={`cursor-pointer w-full md:mx-0 uppercase bg-button-primary my-2 px-12 md:px-6 xl:px-16 py-4 max-w-full md:max-w-[16rem] max-h-[6rem] ${
        wide ? 'lg:max-w-[24rem]' : 'lg:max-w-[16rem]'
      }  lg:max-h-[6rem] xl:max-h[12rem] 2xl:max-w-[32rem] 2xl:max-h-[18rem]`}
      onClick={onClick}
    >
      <p
        className={`font-AJensonProBold mx-auto w-full pt-2 tracking-wide text-agia-primary relative -top-1 ${fontBuilder()}`}
      >
        {message}
      </p>
    </button>
  )
}
export default PrimaryButton
