import React, { useEffect } from 'react'
import PrimaryButton from './PrimaryButton'
import defaultMapImage from '../assets/img/location_map_FPO1.jpg'
import { useDispatch, useSelector } from 'react-redux'
import { setModal, setModalImg, ModalState } from '../feature/modalSlice'
import Modal from './Modal'

const fontClass = {
  default: 'text-sm',
  lg: 'lg:text-xl'
}

type Props = {
  estateName: string
  price: string
  mapImage?: string
  keyDetails: { content: string; style?: string }[]
  furtherDetails: { content: string }[]
}

function EstateFurtherDetails(props: Props) {
  const [mapImage, setMapImage] = React.useState(defaultMapImage)
  const dispatch = useDispatch()
  const { showModal } = useSelector((state: { modal: ModalState }) => state.modal)
  const halfIdx = props.furtherDetails.length / 2
  console.log(halfIdx, indexAlign(halfIdx))

  const firstColArr = props.furtherDetails.slice(0, indexAlign(halfIdx))
  const secondColArr = props.furtherDetails.slice(indexAlign(halfIdx))

  const renderColDetails = (arr: { content: string }[]) => {
    return arr.map((item, index) => (
      <p className='md:mb-3' key={index}>
        {item.content}
      </p>
    ))
  }

  function indexAlign(num: number) {
    if (num - Math.floor(num) === 0) {
      return num + 1
    }
    return Math.ceil(num)
  }

  useEffect(() => {
    const fetchImage = async () => {
      if (props.mapImage) {
        const response = await import(`../assets/estateAssets/maps/${props.mapImage}`)
        setMapImage(response.default)
      }
    }
    fetchImage()
  }, [props.mapImage])

  function handleClickMap() {
    dispatch(setModalImg(mapImage))
    dispatch(setModal(true))
  }

  return (
    <section>
      {showModal && <Modal />}
      <div className='h-6 bg-gradient-to-b from-agia to-white'></div>
      <div className='flex flex-col items-center justify-center py-4 md:py-10 px-4 md:px-12'>
        <header className='text-2xl lg:text-3xl font-AJensonProSemiBold text-agia-primary tracking-normal md:tracking-widest'>
          DETAILS
        </header>

        <div className='flex flex-col justify-center md:flex-row mt-2 md:mt-8 font-AjensonPro font-normal text-agia-primary text-lg md:text-xl lg:text-2xl w-full max-w-4xl  md:ml-20'>
          <div className='flex flex-col basis-1/2 md:mx-4'>{renderColDetails(firstColArr)}</div>
          <div className='flex flex-col basis-1/2 md:mx-4'>{renderColDetails(secondColArr)}</div>
        </div>
        <div className='mt-6 md:mt-2 h-0.5 w-screen bg-gradient-to-r from-transparent via-agia-primary/30 to-transparent'></div>
        <div className='my-6 w-full md:flex md:items-center md:justify-center'>
          <PrimaryButton message='VIEW ON MAP' fontSize={fontClass} onClick={handleClickMap} wide />
        </div>
      </div>
    </section>
  )
}

export default EstateFurtherDetails
