import React from 'react'
import FAQAccordian from './FAQAccordian'
import { Accordian } from '../utils/FAQdata'

type Props = {
  data: Accordian[]
}

const FAQContents: React.FC<Props> = ({ data }) => {
  return (
    <div>
      {data.map(({ id, title, contents }) => {
        const propData = {
          title,
          contents
        }
        return <FAQAccordian {...propData} key={id} />
      })}
    </div>
  )
}

export default FAQContents
