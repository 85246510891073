import React, { useState, MouseEvent, useEffect } from 'react'
import axios from 'axios'
import EstateCard from '../EstateCard'

import { useNavigate, useNavigation } from 'react-router-dom'
import EstateData, { allEstateData, estateData } from '../../utils/EstateDataSample'

function getEstate(): allEstateData {
  return EstateData
}
const EstateSection: React.FC = () => {
  const [choice, setChoice] = useState<number>(0)
  const [estates, setEstates] = useState<allEstateData>()
  const [filteredEstates, setFilteredEstates] = useState()
  const currentChoiceStyle = `border-b border-agia-blue cursor-pointer`
  const notChosenStyle = `hover:border-b hover:border-agia-blue cursor-pointer`

  const handleChoice = (e: MouseEvent<HTMLLIElement>) => {
    const target = e.target as HTMLLIElement
    setChoice(Number(target.id))
  }

  useEffect(() => {
    const resultEstate = getEstate()
    setEstates(resultEstate)
  }, [])

  const handleFilter = (card: any) => {
    switch (choice) {
      case 0:
        return true
      case 1:
        return card.estateName.split(' ').includes('Villa')
      default:
        return card.estateName.split(' ').includes('Pavilion')
    }
  }

  return (
    <section>
      <h1 className='text-center font-AjensonPro text-2xl lg:text-4xl text-title-blue mx-[10%] py-1 md:py-4 border-b-2 border-[#c7c8ca]'>
        RESERVE YOUR PAVILION OR HOMESITE
      </h1>
      <nav className='flex items-center justify-evenly font-AjensonPro text-agia-blue text- lg:text-xl xl:text-2xl 2xl:text-3xl my-6 lg:mb-16 xl:mb-20'>
        <ul className='flex w-[90%] max-w-lg md:max-w-none md:w-[50%] lg:w-[40%] justify-evenly'>
          <li
            onClick={handleChoice}
            id='0'
            className={choice === 0 ? currentChoiceStyle : notChosenStyle}
          >
            ALL
          </li>
          <li
            onClick={handleChoice}
            id='1'
            className={choice === 1 ? currentChoiceStyle : notChosenStyle}
          >
            VILLA HOMESITES
          </li>
          <li
            onClick={handleChoice}
            id='2'
            className={choice === 2 ? currentChoiceStyle : notChosenStyle}
          >
            PAVILIONS
          </li>
        </ul>
      </nav>
      <div className='grid grid-flow-row grid-cols-2 md:grid-cols-4 3xl:grid-cols-5 lg:gap-6 xl:gap-8 2xl:gap-20 px-4 lg:px-6 xl:px-16 2xl:px-24 mb-4 md:mb-[5vw]'>
        {estates
          ? Object.values(estates)
              .filter(handleFilter)
              .map((card, index) => (
                <EstateCard
                  key={card.id}
                  id={String(card.id)}
                  villa={card.estateName.split(' ').includes('Villa')}
                  category={card.estateName}
                  price={card.price}
                  fullPrice={card.keyDetails[3].content.split('$')[1].slice(0, -3)}
                  houseSize={card.keyDetails[1].content.split(' ')[1]}
                  bedroom={1}
                  bathroom={1}
                  lotSize={card.keyDetails[2].content}
                  sold={card.sold}
                  src={card.estateImage}
                  alt={'An NFT of ' + card.estateName}
                />
              ))
          : null}
      </div>
    </section>
  )
}
export default EstateSection
