import React from 'react'
import COImage from '../assets/img/_KFP0824.jpg'
import PrimaryButton from './PrimaryButton'
import { LandingHeaderText, LandingParagraphText } from './AgiaTextFonts'

function MiddleInfoBottom() {
  const handleDownload = () => {
    (window as any).gtag('event', 'select_content', {content_type: 'brochure', item_id: 'discovery_brochure' });
    window.open(
      'https://koi-3qvul5rg5w.marketingautomation.services/net/m?md=%2BO0iCH9gRNgeAb8dDTDNOyprSoib5ot2',
      '_blank',
      'noreferrer'
    )
  }
  const handleDownload2 = () => {
    (window as any).gtag('event', 'select_content', {content_type: 'brochure', item_id: 'lifestyle_brochure' });
    window.open(
      'https://koi-3qvul5rg5w.marketingautomation.services/net/m?md=Jw7EexOzRF9V1K8FHyx5J8CHaIiLTvC%2B',
      '_blank',
      'noreferrer'
    )
  }
  return (
    <div>
      <div className='bg-hidden md:bg-middlePageBackground bg-cover relative'>
        <div className='hidden md:block h-10 lg:h-12 w-full bg-gradient-to-b from-white to-transparent absolute top-0'></div>

        <div className='flex flex-col md:flex-row px-4 lg:px-24 2xl:px-36 py-4 lg:py-12 bg-gradient-to-r from-white via-white/90 to-transparent'>
          <div className='flex flex-col items-center justify-center w-full md:w-6/12 text-center'>
            <LandingHeaderText
              text='A CELEBRATION OF CULTURE AND PLACE'
              extraClassNames='w-11/12 mb-1 max-w-3xl'
            />

            <p className='font-AJensonProSemiBold text-xl lg:text-2xl 2xl:text-4xl text-agia-grey'>
              Designed by Chad Oppenheim.
            </p>
            
            <LandingParagraphText
              text='Grecian-inspired and awash with soft hues, your home on AGIA stands apart while blending
              beautifully into the pure natural landscape. One-bedroom Pavilions deliver turn-key
              luxury, while Villa homesites offer the opportunity to customize your home.'
              extraClassNames='w-full my-3 lg:my-10 mx-6 lg:mx-12'
            />
            <PrimaryButton message={'DOWNLOAD DISCOVERY BROCHURE'} onClick={handleDownload} wide />
            <PrimaryButton message={'DOWNLOAD LIFESTYLE BROCHURE'} onClick={handleDownload2} wide />
          </div>

          <div className='hidden md:block w-6/12 py-12 pl-12  2xl:py-24 2xl:pl-24 justify-center'>
            <img src={COImage} className='w-full' />
          </div>
          <div className='hidden md:block h-10 lg:h-12 w-full bg-gradient-to-t from-white to-transparent absolute bottom-0'></div>
        </div>
      </div>
    </div>
  )
}

export default MiddleInfoBottom
