import React, { useEffect } from 'react'
import ShowcaseSection from '../components/EstatePage/ShowcaseSection'
import EstateInfoSection from '../components/EstatePage/EstateInfoSection'
import EstateHeader from '../components/EstateHeader'
import FormAndFooter from '../components/LandingPage/FormAndFooterSection'
import { useParams } from 'react-router-dom'

const EstatePage: React.FC = () => {
  const params = useParams<{ id: string }>()

  useEffect(() => {
    document.title = 'Agia - Property ' + params?.id;
  }, [params]);

  // to do: load estate data here and send it to EstateInfoSection and ShowcaseSection
  return (
    <div>
      <EstateHeader />
      <EstateInfoSection id={params.id || '0'} />
      <ShowcaseSection id={params.id || '0'} />
      <FormAndFooter />
    </div>
  )
}
export default EstatePage
