import React, { MouseEvent } from 'react'

type Props = {
  message: string
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
  style: string
}

const FAQCategoryButton: React.FC<Props> = ({ message, onClick, style }) => {
  return (
    <button onClick={onClick} className={style}>
      {message}
    </button>
  )
}
export default FAQCategoryButton
