import React from 'react'
import { LandingHeaderText } from '../components/AgiaTextFonts'

type PropsText = {
  heading: string
  body: string
}
type PropsList = {
  team: string[]
}
export const ConstructionText: React.FC<PropsText> = ({ heading, body }) => {
  return (
    <div className='px-4 xl:my-6 2xl:my-8 flex flex-col items-center justify-center'>
      <LandingHeaderText text={heading} />
      <div className='text-center font-AjensonPro font-normal text-md md:text-lg xl:text-1xl 2xl:text-2xl text-agia-primary md:w-[95%] xl:w-[75%] mt-2 xl:mt-4'>
        <p className='leading-relaxed'>{body}</p>
      </div>
    </div>
  )
}

export const ConstructionTeamList: React.FC<PropsList> = ({ team }) => {
  return (
    /*  */
    <ul className='capitalize font-AjensonPro text-center text-agia-primary md:text-xl xl:text-2xl 2xl:text-3xl'>
      {team.map((name: string) => (
        <li className='leading-normal' key={name}>
          {name}
        </li>
      ))}
    </ul>
  )
}
export default ConstructionText
