import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ModalState } from '../feature/modalSlice'

const useLockScroll = () => {
  const { showModal } = useSelector((state: { modal: ModalState }) => state.modal)
  useEffect(() => {
    const body: any = document.querySelector('body')
    body.style.overflowY = showModal ? 'hidden' : 'auto'
  }, [showModal])
}
export default useLockScroll
