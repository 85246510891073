import React from 'react'
import MiddleInfoTop from '../MiddleInfoTop'
import MiddleInfoBottom from '../MiddleInfoBottom'

function MiddleInfoSection() {
  return (
    <div>
      <MiddleInfoTop />
      <MiddleInfoBottom />
    </div>
  )
}

export default MiddleInfoSection
