import React, { useState, useEffect } from 'react'
import { AiOutlineCaretRight } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import useWindowSize from '../utils/useWindowSize'

type Props = {
  date: string
}

const CountDownBar: React.FC<Props> = ({ date }) => {
  const { width } = useWindowSize()
  const [days, setDays] = useState<string>()
  const [hours, setHours] = useState<string>()
  const [minutes, setMinutes] = useState<string>()
  const [_seconds, setSeconds] = useState<string>()
  const [_, setFinish] = useState<boolean>(false)
  const navigate = useNavigate()
  const handleNavigation = () => {
    navigate('/gallery')
  }
  const format = (time: number) => {
    if (time < 10) {
      return `0${time}`
    }
    return `${time}`
  }

  useEffect(() => {
    const countDownTarget = new Date(date)
    const interval = setInterval(() => {
      const currentDate = new Date()
      const difference = countDownTarget.getTime() - currentDate.getTime()
      const d = Math.floor(difference / (1000 * 60 * 60 * 24))
      const h = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
      const s = Math.floor((difference % (1000 * 60)) / 1000)
      setDays(format(d))
      setHours(format(h))
      setMinutes(format(m))
      setSeconds(format(s))
      if (d <= 0 && h <= 0 && m <= 0 && s <= 0) {
        setFinish(true)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <button
      onClick={handleNavigation}
      className='cursor-pointer flex items-center justify-center w-full leading-tight h-20 md:h-24 lg:h-32 3xl:h-48 max-w-[25rem] lg:max-w-full lg:w-[43vw] font-AJensonProSemiBold md:mx-8 mx-4 lg:mt-6'
    >
      <div className='text-agia-primary w-[75%] lg:w-[80%] h-full bg-countdown-primary flex flex-col items-center justify-center p-4 xl:p-6'>
        <p className='flex items-end justify-center w-full font-regular lg:tracking-[0.1rem]  text-sm sm:text-lg xl:text-2xl 2xl:text-3xl 3xl:text-[2.9rem]  text-center md:mb-2 2xl:my-2 3xl:my-4'>
          EXPLORE PROPERTIES
        </p>
        {/* <p className='flex items-start justify-center w-full font-regular lg:tracking-[0.1rem] text-sm sm:text-lg xl:text-2xl 2xl:text-3xl 3xl:text-[2.9rem] text-center 2xl:my-2 3xl:my-4'>
          {`${days} DAYS | ${hours} HRS | ${minutes} MIN`}
        </p> */}
      </div>
      <div className='bg-countdown-text w-[25%] h-full text-4xl text-agia flex items-center justify-center'>
        <AiOutlineCaretRight size={width < 768 ? '8vw' : '6vw'} />
      </div>
    </button>
  )
}
export default CountDownBar
